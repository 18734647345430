import { notification, message } from "antd";
import Rollbar from 'rollbar'

export const messageConfig = () => {
  return message.config({
    duration: 2,
    top: 96
  });
}

export const notificationConfig = () => {
  return notification.config({
    duration: 3,
    placement: 'bottomRight'
  });
}

export const rollbarConfig = {
  accessToken: process.env.RECT_APP_ROLLBAR_ENV === "production" ? process.env.REACT_APP_ROLLBAR_CLIENT_POST_ACCESS_TOKEN: "",
  environment: process.env.RECT_APP_ROLLBAR_ENV === "production" ? process.env.RECT_APP_ROLLBAR_ENV : "",
};

export const rollbar = new Rollbar(rollbarConfig)

export const LANGUAGES = [
  {id: "en", label: "English"},
  {id: "fr", label: "French"}
]