import React, { lazy, Suspense } from "react";
import "antd/dist/antd.less";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { history } from "./util/helpers/browserHistory";
import { messageConfig } from "./util/configs";
import { notificationConfig } from "./util/configs";
import checkAuth from "./util/helpers/checkAuth";
import AuthContainer from "./containers/Auth";
import SetStaffPasswordContainer from "./containers/Staff/set-password";
import Routes from "./authed-routes";
import { Loader } from "./reusable-components/Spinner";

//function to retry lazy imports up to x times if it fails, before it errors out
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

// code splitting...
const NotfoundPromise = import("./containers/NotFound");
const Notfound = lazy(() => retry(() => NotfoundPromise));

const AuthenticatedRoute = ({ component: Component, perform, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default class App extends React.Component {
  render() {
    messageConfig();
    notificationConfig();

    return (
      <Router history={history}> 
        <Suspense fallback={<Loader />}>
          <div>
            <Switch>
              <Route path="/" exact component={AuthContainer} />
              <Route path="/forgot/password" exact component={AuthContainer} />
              <Route
                path="/reset/password/:uid/:token"
                exact
                component={AuthContainer}
              />
              <Route
                path="/user/activate/:uid/:token"
                exact
                component={SetStaffPasswordContainer}
              />
              <AuthenticatedRoute path="/app" component={Routes} />
              <Route component={Notfound} />
            </Switch>
          </div>
        </Suspense>
      </Router>
    );
  }
}
