import { serializeQueryParamsOnNull } from "../../../util/helpers/commonHelper";
import { apiRequest } from "../apiRequest";
import { TRAININGS_ENDPOINT } from "../urls";

export const fetchTrainings = (query) => {
  const queryStr = serializeQueryParamsOnNull({...query, limit: 20})
	return apiRequest("GET", `${TRAININGS_ENDPOINT}${queryStr}`);
};

export const fetchTrainingById = (id) => {
  return apiRequest("GET", `${TRAININGS_ENDPOINT}/${id}`)
}

export const updateTraining = (id, payload) => {
  return apiRequest(
    "PUT",
    `${TRAININGS_ENDPOINT}/${id}/`,
    payload
  )
}

export const syncTrainings = (query) => {
  const queryStr = serializeQueryParamsOnNull(query)
  return apiRequest(
    "GET",
    `/trainings/sync_trainings/${queryStr}`
  )
}

export const trackTrainingSync = (taskId) => {
	return apiRequest(
		"GET",
		`/celery-progress/${taskId}`
	)
}

