import { apiRequest } from "../apiRequest";
import { PROMOTIONS_ENDPOINT } from "../urls";
import { serializeQueryParams } from "../../../util/helpers/commonHelper"

export const PROMOTIONS_LIMIT=10;

export const fetchPromotions = (query) => {
	const queryStr = serializeQueryParams({ limit: PROMOTIONS_LIMIT, ...query })
	return apiRequest(
		"GET",
		`${PROMOTIONS_ENDPOINT}${queryStr}`
	);
};

export const fetchPromotion = (id) => {
	return apiRequest("GET", `${PROMOTIONS_ENDPOINT}/${id}/`);
};

export const createPromotion = (payload) => {
	return apiRequest("POST", `${PROMOTIONS_ENDPOINT}/`, payload)
}


export const putPromotion = (id, paylod) => {
	return apiRequest("PUT", `${PROMOTIONS_ENDPOINT}/${id}`, paylod)
}

export const patchPromotion = (id, paylod) => {
	return apiRequest("PATCH", `${PROMOTIONS_ENDPOINT}/${id}`, paylod)
}

export const deletePromotion = (id) => {
	return apiRequest("DELETE", `${PROMOTIONS_ENDPOINT}/${id}`)
}
