import React, { useEffect } from 'react';
import { Button, Spin, Row, Card } from 'antd';

import SetPasswordForm from '../../Auth/components/ResetPassword';
import { history } from '../../../util/helpers/browserHistory';
import '../../../styles/staff.less'


const SetStaffPassword = props => {
    useEffect(() => {
        const { uid, token } = props.match.params;
        props.checkLinkValidity(uid, token);
        // eslint-disable-next-line
    }, []);

    return (
        <Row>
            <Card className="staff-set-password">
                {props.isLoading && <Spin />}
                {props.error && <>
                    <span>Link has already been used or expired.</span>
                    <br />
                    <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => history.push("/")}
                    >
                        click here to login
                    </Button>
                    </span>
                </>}
                {!props.isLoading && !props.error && <SetPasswordForm
                        buttonText="Set"
                        title="Set Password"
                        reset={props.reset}
                        setPassword={props.resetPassword}
                        setPasswordEmail={props.email}
                        isloading={props.isSetting}
                        match={props.match}
                        passwordSet={props.passwordSet}
                    />}
            </Card>
        </Row>
    )
}

export default SetStaffPassword;
