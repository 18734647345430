import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_DISTRICTS
  FETCH_DISTRICTS_SUCCESS
  FETCH_DISTRICTS_FAILURE
  RESET_DISTRICTS
  `,
  {}
);