import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/districts/actions";
import {
  fetchDistricts as fetchDistrictsApi
} from '../../api/districts'
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchDistricts(actions) {
  try {
    const { query } = actions
    const response = yield call(fetchDistrictsApi, query)
    const data = response && response.data
    yield put(Creators.fetchDistrictsSuccess(data, null))
  } catch (error) {
    rollbar.error(`Api call fetch districts error ${error}`)
    OpenNotificationWithIcon("error", "Get Districts", "failed to fetch districts")
    yield put(Creators.fetchDistrictsFailure)
  }
}

export function* resetDistricts(actions) {
  yield put(Creators.resetDistricts());
}

export function* watchFetchDistricts() {
  yield takeLatest(Types.FETCH_DISTRICTS, fetchDistricts)
}

export function* watchResetDistricts() {
  yield takeLatest(Types.RESET_DISTRICTS, resetDistricts)
}