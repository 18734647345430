import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_PAYMENTS
  FETCH_PAYMENTS_SUCCESS
  FETCH_PAYMENTS_FAILURE

  FETCH_PAYMENT_DETAIL
  FETCH_PAYMENT_DETAIL_SUCCESS
  FETCH_PAYMENT_DETAIL_FAILURE

  RESET

`,
  {}
);