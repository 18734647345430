import { apiRequest, apiBlobRequest } from "../apiRequest";
import { USERS_ENDPOINT, SIGN_UP_ENDPOINT } from "../urls";
import { serializeQueryParamsOnNull } from "../../../util/helpers/commonHelper"

export const fetchUsers = (query) => {
	console.log('query object', query)
	let search_term = query.search_term || null;
	let company_id = query.company_id || null;
	let status = query?.status;
	let is_active = query?.is_active;
	let page = query && query.page;
	let limit = 50;
	let district_id = query.district_id || null
	let cluster_id = query.cluster_id || null

	const qBody = {
		search: search_term,
		company_id,
		is_active: status ? is_active : undefined,
		city: district_id,
		street: cluster_id,
		page,
		limit
	}

	const queryStr = serializeQueryParamsOnNull(qBody)

	return apiRequest(
		"GET",
		`${USERS_ENDPOINT}${queryStr}`);

	

	// return apiRequest(
	// 	"GET",
	// 	`${USERS_ENDPOINT}/?
	// 	${search_term ? `&search=${search_term}` : ""}
	// 	${company_id ? `&company_id=${company_id}` : ""}
	// 	${status ? `&is_active=${is_active}` : ""}
	// 	${district_id ? `&city=${district_id}` : ""}
	// 	${cluster_id ? `&street=${cluster_id}` : ""}
	// 	${page ? `&page=${page}` : ""}
	// 	${limit ? `&limit=${limit}` : ""}`
	// );
};

export const refreshUsers = (query) => {
	let { limit, page } = query
	return apiRequest(
		"GET",
		`/user/admin/refresh-ches?
		${limit ? `limit=${limit}` : ""}
		${page ? `&page=${page}` : "&page=1"}
		`
	)
}

export const getUser = (id) => {
	return apiRequest("GET", `${USERS_ENDPOINT}/${id}/`);
};

export const createUser = (data) => {
	return apiRequest("POST", SIGN_UP_ENDPOINT, data);
};

export const updateUser = (id, data) => {
	return apiRequest("PATCH", `${USERS_ENDPOINT}/${id}/`, data);
};

export const fetchUser = (id) => {
	return apiRequest("GET", `${USERS_ENDPOINT}/${id}/`);
};

export const resetChePassword = (id) => {
	return apiRequest("POST", `${USERS_ENDPOINT}/${id}/generate_password/`, {});
};

export const deactivateUser = (id, data) => {
	return apiRequest("PUT", `${USERS_ENDPOINT}/${id}/activate/`, data);
};


export const generateChesPasswords = (userIds) => {
	return apiBlobRequest("POST", `${USERS_ENDPOINT}/generate_passwords/`, {che_user_ids: userIds});
}

export const generateChesActivationQrcode = (payload) => {
  console.log('generate_ches_aqr_code_api_call', payload)
  return apiRequest("POST", `/user/auth/qr_code`, payload)
}

export const trackUserSync = (taskId) => {
	return apiRequest(
		"GET",
		`/celery-progress/${taskId}`
	)
}


export const getLastSync = () => {
  return apiRequest(
    "GET",
    `user/admin/refresh-ches?last_run=true`
  )
}