import { connect } from "react-redux";

import { Creators } from "../../../services/redux/staff/actions";
import { Creators as AuthCreator } from "../../../services/redux/auth/actions";
import SetPassword from "./validation";

const mapStateToProps = (state) => {
  return {
    isLoading: state.staff.isCheckingSetPasswordLink,
    error: state.staff.errors,
    isSetting: state.staff.isSettingPassword,
    email: state.staff.email,
    passwordSet: state.staff.passwordSet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLinkValidity: (uuid, token) => {
      dispatch(Creators.validateSetPasswordStaffRequest(uuid, token));
    },
    reset: () => {
      dispatch(AuthCreator.reset());
    },
    resetPassword: (token, email, password) => {
      dispatch(Creators.setPasswordStaffRequest(token, email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
