import { apiRequest, api, apiCustomised } from "../apiRequest";
import {
	LOGIN_ENDPOINT,
	SIGN_UP_ENDPOINT,
	FORGOT_PASSWORD_ENDPOINT,
	RESET_PASSWORD_ENDPOINT,
	CHANGE_PASSWORD_ENDPOINT,
	USER_PROFILE_ENDPOINT,
  SAVE_DEVICE_TOKEN
} from "../urls";

export const login = (data) => {
	return api("POST", LOGIN_ENDPOINT, data);
};

export const signUp = (data) => {
	return api("POST", SIGN_UP_ENDPOINT, data);
};

export const forgotPassword = (data) => {
	return api("POST", FORGOT_PASSWORD_ENDPOINT, data);
};

export const resetPassword = (token, data) => {
	return apiCustomised("PATCH", RESET_PASSWORD_ENDPOINT, data, token);
};

export const changePassword = (id, data) => {
	return apiRequest("PATCH", `${CHANGE_PASSWORD_ENDPOINT}/${id}/`, data);
};

export const getUserDetails = () => {
	return apiRequest("GET", USER_PROFILE_ENDPOINT, {});
};

export const saveDeviceToken = (data) => {
	return apiRequest("POST", SAVE_DEVICE_TOKEN, data);
};
