import { all } from "redux-saga/effects";
import {
	watchLogin,
	watchForgotPassword,
	watchResetPassword,
	watchLogout,
	watchChangePassword,
	watchGetUserDetails,
  watchSaveDeviceToken,
} from "./auth";
import {
	watchCreateUser,
	watchFetchUsers,
	watchGetUser,
	watchUpdateUser,
	watchResetChePassword,
	watchDeactivateUser,
	watchGenerateChesPasswords,
	watchRefreshUsers,
  watchGenerateChesActivationQrcode,
  watchSyncUsers,
  watchTrackUserSync,
  watchGetLastSync,
} from "./users";
import { watchFetchCountries, watchUpdateCountry } from "./country";
import {
	watchDeleteStaff,
	watchFetchStaff,
	watchAddStaff,
	watchEditStaff,
	watchVaalidateSetPasswordLinkStaff,
	watchSetStaffPassword,
} from "./staff";
import { watchFetchOrders, watchFetchOrderDetails } from "./orders";
import { watchFetchPaymentMethods } from "./payment_methods";
import { 
	watchFetchUserGroups, 
	watchFetchUserGroupProducts, 
	watchBlackList, 
	watchUpdateUserGroup,
	watchCreateUserGroupPromotionMessage,
	watchUpdateUserGroupPromotionMessage,
} from "./user_groups";
import { watchFetchDistricts } from './districts'
import { watchFetchClusters } from "./clusters";

import {
	watchFetchPromotions,
	watchCreatePromotion,
	watchDeletePromotion,
	watchUpdatePromotion,
} from "./promotions"
import { watchFetchPaymentTerms } from "./accounting";
import { watchFetchPaymentDetails, watchFetchPayments } from "./payments";
import { watchFetchTrainingById, watchFetchTrainings, watchGetLastTrainingsSync, watchSyncTrainings, watchUpdateTraining, watchtrackTrainingsSync } from "./trainings";
import { watchFetchPricelists, watchFetchPricelistsByCountry } from "./pricelist";

export default function* rootSaga() {
	yield all([
		watchLogin(),
		watchForgotPassword(),
		watchResetPassword(),
		watchChangePassword(),
		watchDeactivateUser(),
    watchSaveDeviceToken(),
		watchLogout(),
		watchCreateUser(),
		watchFetchUsers(),
		watchGetUser(),
		watchUpdateUser(),
		watchFetchCountries(),
		watchResetChePassword(),
    watchGenerateChesActivationQrcode(),
		watchFetchStaff(),
		watchDeleteStaff(),
		watchAddStaff(),
		watchEditStaff(),
		watchVaalidateSetPasswordLinkStaff(),
		watchSetStaffPassword(),
		watchFetchOrders(),
		watchFetchOrderDetails(),
		watchGetUserDetails(),
		watchFetchPaymentMethods(),
		watchUpdateCountry(),
		watchFetchUserGroups(),
		watchFetchUserGroupProducts(),
		watchBlackList(),
		watchFetchDistricts(),
		watchFetchClusters(),
		watchGenerateChesPasswords(),
		watchFetchPromotions(),
		watchCreatePromotion(),
		watchDeletePromotion(),
		watchUpdateUserGroup(),
		watchCreateUserGroupPromotionMessage(),
		watchUpdateUserGroupPromotionMessage(),
		watchUpdatePromotion(),
		watchRefreshUsers(),
    watchSyncUsers(),
    watchGetLastSync(),
    watchTrackUserSync(),
		watchFetchPaymentTerms(),
    watchFetchPayments(),
    watchFetchPaymentDetails(),

    watchFetchTrainings(),
    watchFetchTrainingById(),
    watchUpdateTraining(),
    watchSyncTrainings(),
    watchGetLastTrainingsSync(),
    watchtrackTrainingsSync(),

    watchFetchPricelists(),
    watchFetchPricelistsByCountry()
	]);
}
