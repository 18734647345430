import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { Creators } from "../services/redux/auth/actions";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../containers/Layout";

//function to retry lazy imports up to x times if it fails, before it errors out
const retry = (fn, retriesLeft = 5, interval = 1000) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

// code splitting...
const UsersListPromise = import("../containers/Ches/list-users");
const UsersList = lazy(() => retry(() => UsersListPromise));

const UserViewPromise = import("../containers/Ches/view-user");
const UserView = lazy(() => retry(() => UserViewPromise));

const StaffListPromise = import("../containers/Staff/list-staffs");
const StaffList = lazy(() => retry(() => StaffListPromise));

const NewStaffPromise = import("../containers/Staff/add-staff");
const NewStaff = lazy(() => retry(() => NewStaffPromise));

const EditStaffPromise = import("../containers/Staff/edit-staff");
const EditStaff = lazy(() => retry(() => EditStaffPromise));

const OrdersListPromise = import("../containers/Orders/list-orders");
const OrdersList = lazy(() => retry(() => OrdersListPromise));

const PaymentListPromise = import("../containers/Payments")
const PaymentList = lazy(() => retry(() => PaymentListPromise))

const OrderDetailsPromise = import(
	"../containers/Orders/list-orders/components/order-details"
);
const OrderDetails = lazy(() => retry(() => OrderDetailsPromise));

const CompaniesListPromise = import("../containers/Companies/list-companies");
const CompaniesList = lazy(() => retry(() => CompaniesListPromise));

// const UserGroupListPromise = import("../containers/UserGroups/list-groups");
// const UserGroupList = lazy(() => retry(() => UserGroupListPromise));

const TrainingListPromise = import("../containers/Trainings/list-trainings");
const  TrainingList = lazy(() => retry(() => TrainingListPromise));

const TrainingViewPromise = import("../containers/Trainings/view-training");
const TrainingView = lazy(() => retry(() => TrainingViewPromise));

// const UserGroupViewPromise = import("../containers/UserGroups/view-user-group");
// const UserGroupView = lazy(() => retry(() => UserGroupViewPromise));

const PromotionsPromise = import("../containers/Promotions")
const PromotionsView = lazy(() => retry(()=> PromotionsPromise));

const NotfoundPromise = import("../containers/NotFound");
const Notfound = lazy(() => retry(() => NotfoundPromise));

class Routes extends Component {
	render() {
		const { match } = this.props;
		const routes = [
			{
				path: "/ches",
				exact: true,
				component: UsersList,
			},
			{
				path: "/view/che",
				exact: true,
				component: UserView,
			},
			{
				path: "/staff",
				exact: true,
				component: StaffList,
			},
			{
				path: "/add-staff",
				exact: true,
				component: NewStaff,
			},
      {
				path: "/payments",
				exact: true,
				component: PaymentList,
			},
			{
				path: "/edit-staff/:id",
				exact: true,
				component: EditStaff,
			},
			{
				path: "/",
				exact: true,
				render: (props) => <Redirect to={{ pathname: "/app/orders" }} />,
			},
			{
				path: "/orders",
				exact: true,
				component: OrdersList,
			},
			{
				path: "/order/:id",
				exact: true,
				component: OrderDetails,
			},
			{
				path: "/companies",
				exact: true,
				component: CompaniesList,
			},
      {
				path: "/trainings",
				exact: true,
				component: TrainingList
			},
      {
				path: "/trainings/:id",
				exact: true,
				component: TrainingView,
			},
			{
				path: "/promotions",
				exact: true,
				component: PromotionsView
			},
			{
				path: "*",
				exact: true,
				component: Notfound,
			},
		];
		return (
			<Layout>
				<Switch>
					{routes.map((route, i) => {
						return (
							<Route
								key={i}
								exact={route.exact}
								path={`${match.url}${route.path}`}
								render={route && route.render}
								component={route.component}
							/>
						);
					})}
				</Switch>
			</Layout>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(Creators.logout());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
