import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchOrders: ["query"],
    fetchOrdersSuccess: ["responseData", "totalCount"],
    fetchOrdersFailure: ["error"],

    fetchOrderDetail: ["id"],
    fetchOrderDetailSuccess: ["responseData"],
    fetchOrderDetailFailure: ["error"],

    reset: null,
  },
  {}
);
