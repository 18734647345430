import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  staffs: null,
  isFetching: false,

  id: null,
  isDeleting: false,
  isAdding: false,
  isEditing: false,
  isCheckingSetPasswordLink: false,
  isSettingPassword: false
};

export const getStaff = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: true };
};

export const getStaffSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, staffs: action.responseData, isFetching: false };
};

export const getStaffFailure = (state = INITIAL_STATE, action) => {
  return { ...state, staffs: null, isFetching: false, errors: action.error };
};

export const deleteStaff = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: true, id: action.id };
};

export const deleteStaffSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: false, id: null };
};

export const deleteStaffFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: false, errors: action.error, id: null };
};

export const addStaff = (state = INITIAL_STATE, action) => {
  return { ...state, isAdding: true };
};

export const addStaffSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isAdding: false };
};

export const addStaffFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isAdding: false, errors: action.error };
};

export const editStaff = (state = INITIAL_STATE, action) => {
  return { ...state, isEditing: true };
};

export const editStaffSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isEditing: false };
};

export const editStaffFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isEditing: false, errors: action.error };
};

export const checkStaffSetPasswordLink = (state = INITIAL_STATE, action) => {
  return { ...state, isCheckingSetPasswordLink: true };
};

export const checkStaffSetPasswordLinkSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isCheckingSetPasswordLink: false, email: action.responseData };
};

export const checkStaffSetPasswordLinkFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isCheckingSetPasswordLink: false, errors: action.error };
};

export const setStaffSetPassword = (state = INITIAL_STATE, action) => {
  return { ...state, isSettingPassword: true };
};

export const setStaffSetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isSettingPassword: false, email: action.responseData, passwordSet: true };
};

export const setStaffSetPasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isSettingPassword: false, errors: action.error };
};

export const HANDLERS = {
  [Types.GET_STAFF_REQUEST]: getStaff,
  [Types.GET_STAFF_SUCCESS]: getStaffSuccess,
  [Types.GET_STAFF_FAILURE]: getStaffFailure,

  [Types.DELETE_STAFF_REQUEST]: deleteStaff,
  [Types.DELETE_STAFF_SUCCESS]: deleteStaffSuccess,
  [Types.DELETE_STAFF_FAILURE]: deleteStaffFailure,

  [Types.ADD_STAFF_REQUEST]: addStaff,
  [Types.ADD_STAFF_SUCCESS]: addStaffSuccess,
  [Types.ADD_STAFF_FAILURE]: addStaffFailure,

  [Types.EDIT_STAFF_REQUEST]: editStaff,
  [Types.EDIT_STAFF_SUCCESS]: editStaffSuccess,
  [Types.EDIT_STAFF_FAILURE]: editStaffFailure,

  [Types.VALIDATE_SET_PASSWORD_STAFF_REQUEST]: checkStaffSetPasswordLink,
  [Types.VALIDATE_SET_PASSWORD_STAFF_SUCCESS]: checkStaffSetPasswordLinkSuccess,
  [Types.VALIDATE_SET_PASSWORD_STAFF_FAILURE]: checkStaffSetPasswordLinkFailure,

  [Types.SET_PASSWORD_STAFF_REQUEST]: setStaffSetPassword,
  [Types.SET_PASSWORD_STAFF_SUCCESS]: setStaffSetPasswordSuccess,
  [Types.SET_PASSWORD_STAFF_FAILURE]: setStaffSetPasswordFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
