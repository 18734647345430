import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
	errors: null,
	countries: [],
	isloading: false,
	company: null,
	sync: false,
	isEditting: false,
	isUpdated: false,
};

export const fetchCountries = (state = INITIAL_STATE, action) => {
	return { ...state, isloading: true, sync: false };
};

export const fetchCountriesSuccess = (state = INITIAL_STATE, action) => {
	return { ...state, countries: action.responseData, isloading: false };
};

export const fetchCountriesFailure = (state = INITIAL_STATE, action) => {
	return { ...state, countries: null, isloading: false, errors: action.error };
};

export const updateCountry = (state = INITIAL_STATE, action) => {
	return { ...state, isEditting: true, isUpdated: false };
};

export const updateCountrySuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		company: action.responseData,
		isEditting: false,
		isUpdated: true,
	};
};

export const updateCountryFailure = (state = INITIAL_STATE, action) => {
	return { ...state, isEditting: false, errors: action.error, isUpdated: false };
};

export const chooseCompany = (state = INITIAL_STATE, action) => {
	return {
		...state,
		company: action.data,
	};
};

export const toggleSync = (state = INITIAL_STATE, action) => {
	return {
		...state,
		sync: !state.sync,
	};
};

export const HANDLERS = {
	[Types.FETCH_COUNTRIES]: fetchCountries,
	[Types.FETCH_COUNTRIES_SUCCESS]: fetchCountriesSuccess,
	[Types.FETCH_COUNTRIES_FAILURE]: fetchCountriesFailure,

	[Types.UPDATE_COUNTRY]: updateCountry,
	[Types.UPDATE_COUNTRY_SUCCESS]: updateCountrySuccess,
	[Types.UPDATE_COUNTRY_FAILURE]: updateCountryFailure,

	[Types.CHOOSE_COMPANY]: chooseCompany,

	[Types.TOGGLE_SYNC]: toggleSync,
};

export default createReducer(INITIAL_STATE, HANDLERS);
