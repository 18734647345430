import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_USER_GROUPS
  FETCH_USER_GROUPS_SUCCESS
  FETCH_USER_GROUPS_FAILURE

  FETCH_USER_GROUP_PRODUCTS
  FETCH_USER_GROUP_PRODUCTS_SUCCESS
  FETCH_USER_GROUP_PRODUCTS_FAILURE

  UPDATE_USER_GROUP
  UPDATE_USER_GROUP_SUCCESS
  UPDATE_USER_GROUP_FAILURE

  CREATE_USER_GROUP_PROMOTION_MESSAGE
  CREATE_USER_GROUP_PROMOTION_MESSAGE_SUCCESS
  CREATE_USER_GROUP_PROMOTION_MESSAGE_FAILURE

  UPDATE_USER_GROUP_PROMOTION_MESSAGE
  UPDATE_USER_GROUP_PROMOTION_MESSAGE_SUCCESS
  UPDATE_USER_GROUP_PROMOTION_MESSAGE_FAILURE

  UPDATE_FREE_PRODUCTS
  UPDATE_FREE_PRODUCTS_SUCCESS
  UPDATE_FREE_PRODUCTS_FAILURE

  BLACK_LIST
  BLACK_LIST_SUCCESS
  BLACK_LIST_FAILURE
  
  ADD_TO_BLACK_LIST

  REMOVE_FROM_BLACK_LIST

  ADD_FREE_PRODUCT
  REMOVE_FREE_PRODUCT
  EDIT_FREE_PRODUCT

  RESET
`,
  {}
);
