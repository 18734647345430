import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/user_groups/actions";
import {
	fetchUserGroups as fetchUserGroupsApi,
	fetchUserGroupProducts as fetchUserGroupProductsApi,
	blackList as blackListApi,
	patchUserGroup as patchUserGroupApi,
	createUserGroupPromotionMessage as createUserGroupPromotionMessageApi,
	updateUserGroupPromotionMessage as updateUserGroupPromotionMessageApi
} from "../../api/user_groups";
// import { history } from "../../../util/helpers/browserHistory";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* getUserGroups(actions) {
	try {
		const response = yield call(fetchUserGroupsApi);
		yield put(Creators.fetchUserGroupsSuccess(response.data));
	} catch (e) {
		rollbar.error(`Api call get user groups error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) ||
			"Cannot Fetch User Groups";
		OpenNotificationWithIcon("error", "Fetching User Groups Failed!", message);
		yield put(Creators.fetchUserGroupsFailure(message));
	}
}

export function* fetchUserGroupProducts(actions) {
	try {
		const { id } = actions;
		const response = yield call(fetchUserGroupProductsApi, id);
		const resp = response && response.data;

		yield put(Creators.fetchUserGroupProductsSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call fetch user group products error ${error}`)
		// let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"User Group Products",
			"Failed to fetch user group products!"
		);
		yield put(Creators.fetchUserGroupProductsFailure());
	}
}

export function* blackList(actions) {
	try {
		const { id, payload } = actions;
    rollbar.info(`BLacklist products request: ${{id, payload}}`)
		const response = yield call(blackListApi, id, payload);
		const resp = response && response.data;
		yield put(Creators.blackListSuccess(resp && resp.data));
    rollbar.info(`Blacklist products response ${response?.data}`)
		OpenNotificationWithIcon(
			"success",
			"Blacklist Update",
			"Blacklist has been updated  successfully!"
		);
		// history.push("/app/user-groups");
	} catch (error) {
		rollbar.error(`Api call blacklist products error ${error}`)
		OpenNotificationWithIcon(
			"error",
			"Blacklist Update",
			"Failed to update blacklist!"
		);
		let errorsFound = error.response && error.response.data;
		yield put(Creators.blackListFailure(errorsFound));
	}
}

export function* updateUserGroup(actions) {
	try {
		const { id, payload } = actions;
		const response = yield call(patchUserGroupApi, id, payload);
		const resp = response && response.data;
		yield put(Creators.updateUserGroupSuccess(resp && resp.data));
		OpenNotificationWithIcon(
			"success",
			"User group Update",
			resp?.message || "User group has been updated  successfully!"
		);
		yield put(Creators.fetchUserGroupProducts(id));
		// history.push("/app/user-groups");
	} catch (error) {
		rollbar.error(`Api call update user group error ${error}`)
		const productIds = error?.response?.data?.free_product_ids || {}
		const errors = productIds[0]?.expiry_date?.error
		console.log('error response', errors)
		const message = errors || error?.response?.data?.message || (error?.response?.data?.expiry_date || [])[0] || "Failed to update user group!"
		
		OpenNotificationWithIcon(
			"error",
			"User group Update",
			message
		);
		yield put(Creators.updateUserGroupFailure(message));
	}
}

export function* createUserGroupPromotionMessage(actions) {
	try {
		const { payload } = actions;
    rollbar.info(`Create user group promotion message request: ${payload}`)
		const response = yield call(createUserGroupPromotionMessageApi, payload);
		const resp = response && response.data;
    rollbar.info(`Create user group promotion message response: ${resp?.data}`)
		yield put(Creators.createUserGroupPromotionMessageSuccess(resp && resp.data));
		OpenNotificationWithIcon(
			"success",
			"User group Update",
			"User group has been updated  successfully!"
		);
		yield put(Creators.fetchUserGroupProducts(payload?.user_group));
		// history.push("/app/user-groups");
	} catch (error) {
		rollbar.error(`Api call create user group promotion message error: ${error}`)
		const message = (error?.response?.data?.expiry_date || [])[0] || error?.response?.data?.message || "Failed to update user group message!"
		OpenNotificationWithIcon(
			"error",
			"User group promotion message",
			message
		);
		yield put(Creators.createUserGroupPromotionMessageFailure(message));
	}
}

export function* updateUserGroupPromotionMessage(actions) {
	try {
		const { id, payload } = actions;
    rollbar.info(`Update usergroup promotion message request: ${{id, payload}}`)
		const response = yield call(updateUserGroupPromotionMessageApi, id, payload);
		yield put(Creators.updateUserGroupPromotionMessageSuccess(response));
    rollbar.info(`Update usergroup promotion message response: ${response}`)
		OpenNotificationWithIcon(
			"success",
			"User group Update",
			"User group has been updated  successfully!"
		);
		yield put(Creators.fetchUserGroupProducts(payload?.user_group));
	} catch (error) {
		rollbar.error(`Api call update user group promotion message error ${error}`)
		const message = (error?.response?.data?.expiry_date || [])[0] || error?.response?.data?.message || "Failed to update user group message!"
		OpenNotificationWithIcon(
			"error",
			"User group promotion message",
			message
		);
		yield put(Creators.updateUserGroupPromotionMessageFailure(message));
	}
}

export function* watchFetchUserGroups() {
	yield takeLeading(Types.FETCH_USER_GROUPS, getUserGroups);
}

export function* watchFetchUserGroupProducts() {
	yield takeLatest(Types.FETCH_USER_GROUP_PRODUCTS, fetchUserGroupProducts);
}

export function* watchBlackList() {
	yield takeLeading(Types.BLACK_LIST, blackList);
}

export function* watchUpdateUserGroup() {
	yield takeLatest(Types.UPDATE_USER_GROUP, updateUserGroup)
}

// export function* watchAddFreeProduct() {
// 	yield takeLatest(Types.ADD_FREE_PRODUCT, addFreeProduct)
// }

// export function* watchRemoveFreeProduct() {
// 	yield takeLatest(Types.REMOVE_FREE_PRODUCT, removeFreeProduct)
// }


export function* watchCreateUserGroupPromotionMessage() {
	yield takeLatest(Types.CREATE_USER_GROUP_PROMOTION_MESSAGE, createUserGroupPromotionMessage)
}

export function* watchUpdateUserGroupPromotionMessage() {
	yield takeLatest(Types.UPDATE_USER_GROUP_PROMOTION_MESSAGE, updateUserGroupPromotionMessage)
}