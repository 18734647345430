import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchUserGroups: [],
    fetchUserGroupsSuccess: ["responseData"],
    fetchUserGroupsFailure: ["error"],

    fetchUserGroupProducts: ["id"],
    fetchUserGroupProductsSuccess: ["responseData"],
    fetchUserGroupProductsFailure: ["error"],

    updateUserGroup: ["id", "payload"],
    updateUserGroupSuccess: ["responseData"],
    updateUserGroupFailure: ["error"],

    createUserGroupPromotionMessage: ["payload"],
    createUserGroupPromotionMessageSuccess: ["responseData"],
    createUserGroupPromotionMessageFailure: ["error"],

    updateUserGroupPromotionMessage: ["id","payload"],
    updateUserGroupPromotionMessageSuccess: ["responseData"],
    updateUserGroupPromotionMessageFailure: ["error"],

    updateFreeProducts: ["id","payload"],
    updateFreeProductsSuccess: ["responseData"],
    updateFreeProductsFailure: ["error"],

    blackList: ["id", "payload"],
    blackListSuccess: ["responseData"],
    blackListFailure: ["error"],

    addToBlackList: ["item"],

    removeFromBlackList: ["item"],

    addFreeProduct: ["item"],
    removeFreeProduct: ["item"],
    editFreeProduct: ["item"],

    reset: null,
  },
  {}
);
