import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_USERS
  FETCH_USERS_SUCCESS
  FETCH_USERS_FAILURE

  REFRESH_USERS
  REFRESH_USERS_SUCCESS
  REFRESH_USERS_FAILURE

  SYNC_USERS
  SYNC_USERS_SUCCESS
  SYNC_USERS_FAILURE

  TRACK_USER_SYNC
  TRACK_USER_SYNC_SUCCESS
  TRACK_USER_SYNC_FAILURE

  CREATE_USER
  CREATE_USER_SUCCESS
  CREATE_USER_FAILURE

  GET_USER
  GET_USER_SUCCESS
  GET_USER_FAILURE

  UPDATE_USER
  UPDATE_USER_SUCCESS
  UPDATE_USER_FAILURE
  
  RESET_CHE_PASSWORD
  RESET_CHE_PASSWORD_SUCCESS
  RESET_CHE_PASSWORD_FAILURE

  DEACTIVATE_USER
  DEACTIVATE_USER_SUCCESS
  DEACTIVATE_USER_FAILURE

  RESET_PASSWORD_GENERATION
  GENERATE_CHES_PASSWORDS
  GENERATE_CHES_PASSWORDS_SUCCESS
  GENERATE_CHES_PASSWORDS_FAILURE

  GENERATE_CHES_ACTIVATION_QRCODE
  GENERATE_CHES_ACTIVATION_QRCODE_SUCCESS
  GENERATE_CHES_ACTIVATION_QRCODE_FAILURE

  SET_FILTER
  RESET_FILTER

  GET_LAST_SYNC
  GET_LAST_SYNC_SUCCESS
  GET_LAST_SYNC_FAILURE

  RESET

`,
  {}
);
