import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchPayments: ["query"],
    fetchPaymentsSuccess: ["responseData", "totalCount"],
    fetchPaymentsFailure: ["error"],

    fetchPaymentDetail: ["id"],
    fetchPaymentDetailSuccess: ["responseData"],
    fetchPaymentDetailFailure: ["error"],

    reset: null,
})