import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_PRICELISTS
  FETCH_PRICELISTS_SUCCESS
  FETCH_PRICELISTS_FAILURE

  FETCH_PRICELISTS_BY_COUNTRY
  FETCH_PRICELISTS_BY_COUNTRY_SUCCESS
  FETCH_PRICELISTS_BY_COUNTRY_FAILURE

  RESET_PRICELISTS
`,
  {}
);
