import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  payments: [],
  totalCount: 0,
  detail: null,
  fetchingDetail: false
};

export const fetchPayments = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchPaymentsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    payments: action.responseData,
    totalCount: action.totalCount,
    isloading: false,
  };
};

export const fetchPaymentsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const fetchPaymentDetails = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, fetchingDetail: true };
};

export const fetchPaymentDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    detail: action.responseData,
    fetchingDetail: false,
  };
};

export const fetchPaymentDetailsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingDetail: false, errors: action.error, detail: null };
};

export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    errors: null,
    payments: [],
    totalCount: null,
  };
};

export const HANDLERS = {
  [Types.FETCH_PAYMENTS]: fetchPayments,
  [Types.FETCH_PAYMENTS_SUCCESS]: fetchPaymentsSuccess,
  [Types.FETCH_PAYMENTS_FAILURE]: fetchPaymentsFailure,

  [Types.FETCH_PAYMENT_DETAIL]: fetchPaymentDetails,
  [Types.FETCH_PAYMENT_DETAIL_SUCCESS]: fetchPaymentDetailsSuccess,
  [Types.FETCH_PAYMENT_DETAIL_FAILURE]: fetchPaymentDetailsFailure,

  [Types.RESET]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);