export function serializeQueryParams(obj) {
  return '?' + Object.keys(obj).reduce(function (a, k) {
    if (obj[k]) {
      a.push(k + '=' + encodeURIComponent(obj[k]));
    }
    return a;
  }, []).join('&');
}

export function serializeQueryParamsOnNull(obj) {
  return '?' + Object.keys(obj).reduce(function (a, k) {
    if (obj[k] !== null && obj[k] !== undefined && obj[k] !== "") {
      a.push(k + '=' + encodeURIComponent(obj[k]));
    }
    return a;
  }, []).join('&');
}

export function downloadFile(data, fileName, fileType) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
    window.navigator.msSaveOrOpenBlob(new Blob([data], { type: fileType }),
      fileName);
  } else {
    const url = window.URL.createObjectURL(new Blob([data], { type: fileType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}

export function getPaymentMethodName(name) {
  let paymentTypeName = name;
  if (name === "momo") {
    paymentTypeName = "MTN"
  }

  return paymentTypeName.charAt(0).toUpperCase() + paymentTypeName.slice(1)
}
