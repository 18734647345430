import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/clusters/actions";
import {
  fetchClusters as fetchClustersApi
} from '../../api/clusters'
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchClusters(actions) {
  try {
    const { query } = actions
    const response = yield call(fetchClustersApi, query)
    const data = response && response.data
    yield put(Creators.fetchClustersSuccess(data, null))
  } catch (error) {
    rollbar.error(`Api call fetch clusters error ${error}`)
    OpenNotificationWithIcon("error", "Get Districts", "failed to fetch districts")
    yield put(Creators.fetchClustersFailure)
  }
}

export function* resetClusters(actions) {
  yield put(Creators.resetClusters())
}

export function* watchFetchClusters() {
  yield takeLatest(Types.FETCH_CLUSTERS, fetchClusters)
}

export function* watchResetClusters() {
  yield takeLatest(Types.RESET_CLUSTERS, resetClusters)
}