import { createTypes } from "reduxsauce";

export default createTypes(
  `
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE

  SIGN_UP_REQUEST
  SIGN_UP_SUCCESS
  SIGN_UP_FAILURE
 
  CHANGE_PASSWORD_REQUEST
  CHANGE_PASSWORD_SUCCESS
  CHANGE_PASSWORD_FAILURE

  FORGOT_PASSWORD
  FORGOT_PASSWORD_SUCCESS
  FORGOT_PASSWORD_FAILURE

  RESET_PASSWORD
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  GET_USER_DETAILS
  GET_USER_DETAILS_SUCCESS
  GET_USER_DETAILS_FAILURE

  SAVE_DEVICE_TOKEN
  SAVE_DEVICE_TOKEN_SUCCESS
  SAVE_DEVICE_TOKEN_FAILURE
 
  LOGOUT

  RESET
`,
  {}
);
