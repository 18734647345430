import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  orders: [],
  totalCount: 0,
  detail: null,
  fetchingDetail: false
};

export const fetchOrders = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchOrdersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    orders: action.responseData,
    totalCount: action.totalCount,
    isloading: false,
  };
};

export const fetchOrdersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const fetchOrderDetails = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, fetchingDetail: true };
};

export const fetchOrderDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    detail: action.responseData,
    fetchingDetail: false,
  };
};

export const fetchOrderDetailsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingDetail: false, errors: action.error, detail: null };
};

export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    errors: null,
    orders: [],
    totalCount: null,
  };
};

export const HANDLERS = {
  [Types.FETCH_ORDERS]: fetchOrders,
  [Types.FETCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
  [Types.FETCH_ORDERS_FAILURE]: fetchOrdersFailure,

  [Types.FETCH_ORDER_DETAIL]: fetchOrderDetails,
  [Types.FETCH_ORDER_DETAIL_SUCCESS]: fetchOrderDetailsSuccess,
  [Types.FETCH_ORDER_DETAIL_FAILURE]: fetchOrderDetailsFailure,

  [Types.RESET]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
