import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isFetchingUserGroups: false,
  userGroups: [],
  userGroupsFetchError: null,

  errors: null,
  isloading: false,
  isFetchingUserGroupProducts: false,
  userGroupProducts: [],
  blackListedProducts: [],
  company: null,
  display_name: null,
  promotion_message: null,
  freeProducts: [],
  updatingUserGroup: false,
  updateUserGroupSuccess: false,
  creatingUserGroupPromotionMessage: false,
  createUserGroupPromotionMessageSuccess: false,
};

export const fetchUserGroups = (state = INITIAL_STATE, action) => {
  return { ...state, userGroupsFetchError: null, isFetchingUserGroups: true };
};

export const fetchUserGroupsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userGroups: action.responseData,
    isFetchingUserGroups: false,
  };
};

export const fetchUserGroupsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUserGroups: false,
    userGroupsFetchError: action.error,
  };
};

export const fetchUserGroupProducts = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetchingUserGroupProducts: true };
};

const processProducts = (products, blacklisted_products_ids) => {
  let user_group_products = [];
  let black_listed_products = [];
  products = products.reduce((acc, cur) => {
    if (typeof cur.code === "boolean") cur.code = null; //filter out booleans
    if (typeof cur.name === "boolean") cur.name = null;
    return (acc = [...acc, cur]);
  }, []);
  user_group_products = products.filter(
    (item) => !blacklisted_products_ids.includes(item.id)
  );
  black_listed_products = products.reduce((acc, cur) => {
    if (blacklisted_products_ids.includes(cur.id)) {
      return (acc = [...acc, cur]);
    }
    return acc;
  }, []);
  return { user_group_products, black_listed_products };
};

// const processFreeProducts = (products, freeProductIds) => {
//   console.log('proces', freeProductIds)
//   return freeProductIds.map(pid => {
//     return products?.find(item => item.id === pid)
//   })
// }

export const fetchUserGroupProductsSuccess = (state = INITIAL_STATE, action) => {
  const {
    products,
    blacklisted_products_ids,
    company,
    display_name,
    promotion_message,
    free_products,
  } = action.responseData;
  return {
    ...state,
    company,
    display_name,
    userGroupProducts: processProducts(products, blacklisted_products_ids || [])
      .user_group_products,
    blackListedProducts: processProducts(products, blacklisted_products_ids || [])
      .black_listed_products,
    isFetchingUserGroupProducts: false,
    promotion_message: promotion_message,
    freeProducts: free_products || []
  };
};

export const fetchUserGroupProductsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingUserGroupProducts: false, errors: action.error };
};

export const addToBlackList = (state = INITIAL_STATE, action) => {
  let blackListed = state.blackListedProducts;
  let products = state.userGroupProducts;
  let item = action.item;
  blackListed = [...blackListed, item];
  products = products.reduce((acc, cur) => {
    if (cur.id !== item.id) {
      return (acc = [...acc, cur]);
    }
    return acc;
  }, []);
  return { ...state, userGroupProducts: products, blackListedProducts: blackListed };
};

export const addToFreeProductList = (state = INITIAL_STATE, action) => {
  let freeProductsListed = state.freeProducts
  let products = state.userGroupProducts;
  let item = action.item
  freeProductsListed = [...freeProductsListed, item]
  products.reduce((acc, cur) => {
    if(cur.id !== item.id) {
      return (acc = [...acc, cur]);
    }
    return acc
  }, [])
  return { ...state, freeProducts: freeProductsListed }
}

export const removeFromBlackList = (state = INITIAL_STATE, action) => {
  let blackListed = state.blackListedProducts;
  let products = state.userGroupProducts;
  let item = action.item;
  products = [...products, item];
  blackListed = blackListed.reduce((acc, cur) => {
    if (cur.id !== item.id) {
      return (acc = [...acc, cur]);
    }
    return acc;
  }, []);
  return { ...state, userGroupProducts: products, blackListedProducts: blackListed };
};

export const blackList = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const blackListSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    // company: null,
    // display_name: null,
    // userGroupProducts: [],
    // blackListedProducts: [],
  };
};

export const blackListFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const addFreeProduct = (state = INITIAL_STATE, action) => {
  const { item } = action
  const product = {
    odoo_id: item?.id,
    name: state.userGroupProducts?.find(it => it.id === item.id)?.name,
    expiry_date: item?.expiry_date,
    min_order_value: item?.min_order_value,
    user_group: item?.user_group
  }
  return { ...state,  freeProducts: [ ...state.freeProducts, product]}
}

export const removeFreeProduct = (state = INITIAL_STATE, action) => {
  const { item } = action
  const freeProducts = state.freeProducts.filter(it => it.odoo_id !== item.odoo_id)
  return { ...state, freeProducts: freeProducts }
}

export const editFreeProduct = (state = INITIAL_STATE, action) => {
  const {item} = action
  const products = state.freeProducts?.map(fp => {
    if(fp.odoo_id === item.id) {
      return {
        odoo_id: item?.id,
        name: state.userGroupProducts?.find(it => it.id === item.id)?.name,
        expiry_date: item?.expiry_date,
        min_order_value: item?.min_order_value,
        user_group: item?.user_group
      }
    } else {
      return fp
    }
  })
  return {...state, freeProducts: products}
}

export const updateUserGroup = (state = INITIAL_STATE, action) => {
  return { ...state,  updatingUserGroup: true, updateUserGroupSuccess: false}
}

export const updateUserGroupSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  return { ...state,  
    updatingUserGroup: false,
    updateUserGroupSuccess: true, 
    promotion_message: responseData.promotion_message,
    
  }
}

export const updateUserGroupFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state,  updatingUserGroup: false, updateUserGroupSuccess: false, error}
}

export const createUserGroupPromotionMessage = (state = INITIAL_STATE, action) => {
  return { ...state, createUserGroupPromotionMessageSuccess: false, creatingUserGroupPromotionMessage: true }
}

export const createUserGroupPromotionMessageSuccess = (state = INITIAL_STATE, action) => {
  return {...state, createUserGroupPromotionMessageSuccess: true, creatingUserGroupPromotionMessage: false }
}

export const createUserGroupPromotionMessageFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, createUserGroupPromotionMessageSuccess: false, creatingUserGroupPromotionMessage: false, error }
}

export const updateUserGroupPromotionMessage = (state = INITIAL_STATE, action) => {
  return { ...state, updateUserGroupPromotionMessageSuccess: false, updatingUserGroupPromotionMessage: true }
}

export const updateUserGroupPromotionMessageSuccess = (state = INITIAL_STATE, action) => {
  return {...state, 
    updateUserGroupPromotionMessageSuccess: true, 
    updatingUserGroupPromotionMessage: false,
    
  }
}

export const updateUserGroupPromotionMessageFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, updateUserGroupPromotionMessageSuccess: false, updatingUserGroupPromotionMessage: false, error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    company: null,
    display_name: null,
    userGroupProducts: [],
    blackListedProducts: [],
  };
};

export const HANDLERS = {
  [Types.FETCH_USER_GROUPS]: fetchUserGroups,
  [Types.FETCH_USER_GROUPS_SUCCESS]: fetchUserGroupsSuccess,
  [Types.FETCH_USER_GROUPS_FAILURE]: fetchUserGroupsFailure,

  [Types.FETCH_USER_GROUP_PRODUCTS]: fetchUserGroupProducts,
  [Types.FETCH_USER_GROUP_PRODUCTS_SUCCESS]: fetchUserGroupProductsSuccess,
  [Types.FETCH_USER_GROUP_PRODUCTS_FAILURE]: fetchUserGroupProductsFailure,

  [Types.BLACK_LIST]: blackList,
  [Types.BLACK_LIST_SUCCESS]: blackListSuccess,
  [Types.BLACK_LIST_FAILURE]: blackListFailure,

  [Types.UPDATE_USER_GROUP]: updateUserGroup,
  [Types.UPDATE_USER_GROUP_SUCCESS]: updateUserGroupSuccess,
  [Types.UPDATE_USER_GROUP_FAILURE]: updateUserGroupFailure,

  [Types.ADD_TO_BLACK_LIST]: addToBlackList,

  [Types.REMOVE_FROM_BLACK_LIST]: removeFromBlackList,

  [Types.ADD_FREE_PRODUCT]: addFreeProduct,
  [Types.REMOVE_FREE_PRODUCT]: removeFreeProduct,
  [Types.EDIT_FREE_PRODUCT]: editFreeProduct,

  [Types.CREATE_USER_GROUP_PROMOTION_MESSAGE]: createUserGroupPromotionMessage,
  [Types.CREATE_USER_GROUP_PROMOTION_MESSAGE_SUCCESS]: createUserGroupPromotionMessageSuccess,
  [Types.CREATE_USER_GROUP_PROMOTION_MESSAGE_FAILURE]: createUserGroupPromotionMessageFailure,

  [Types.UPDATE_USER_GROUP_PROMOTION_MESSAGE]: updateUserGroupPromotionMessage,
  [Types.UPDATE_USER_GROUP_PROMOTION_MESSAGE_SUCCESS]: updateUserGroupPromotionMessageSuccess,
  [Types.UPDATE_USER_GROUP_PROMOTION_MESSAGE_FAILURE]: updateUserGroupPromotionMessageFailure,

  [Types.RESET]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
