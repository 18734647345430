import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchPricelists: ["query"],
    fetchPricelistsSuccess: ["responseData"],
    fetchPricelistsFailure: ["error"],

    fetchPricelistsByCountry: ["country"],
    fetchPricelistsByCountrySuccess: ["responseData"],
    fetchPricelistsByCountryFailure: ["error"],

    resetPricelists: null,
  },
  {}
);