import React, { Component } from "react";
import { Card } from "antd";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Login from "./Login";
import checkAuth from "../../../util/helpers/checkAuth";
import { Redirect } from "react-router-dom";

import "../../../styles/auth.less";

export default class Auth extends Component {
	render() {
		const { location } = this.props;
		let content = null;

		if (checkAuth()) {
			return <Redirect to={{ pathname: "/app" }} />;
		} else {
			if (location.pathname === "/") {
				content = <Login {...this.props} />;
			} else if (location.pathname === "/forgot/password") {
				content = <ForgotPassword {...this.props} />;
			} else {
				content = <ResetPassword {...this.props} />;
			}
		}

		return (
			<div className="auth-container">
				<Card style={{ width: "100%" }}>{content}</Card>
			</div>
		);
	}
}
