import { apiRequest } from "../apiRequest";
import { COUNTRIES_ENDPOINT } from "../urls";

export const fetchCountries = (query) => {
	let update = query && query.update;

	// return apiRequest("GET", COUNTRIES_ENDPOINT);
	return apiRequest(
		"GET",
		`${COUNTRIES_ENDPOINT}/?
		${update ? `update=${update}` : ""}`
	);
};

export const updateCountry = (id, data) => {
	return apiRequest("PATCH", `${COUNTRIES_ENDPOINT}/${id}`, data);
};

export const fetchPricelistsByCountryId = (countryId) => {
  return apiRequest(
    "GET",
    `/country/${countryId}/country_pricelists/`
  )
}
