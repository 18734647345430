import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  is_loading: false,
  trainings: [],
  total_count: 0,
  training: null,
  errors: null,

  updating_training: false,
  update_training_success: false,
  update_training_error: false,
  free_products: [],
  training_products: [],

  syncing: false,
  sync_message: 'Syncing Trainings',
  sync_success: false,
  sync_task_id: null,

  sync_data: null,
  posting_sync: false,

  sync_status: 'init',
  sync_total: 0,
  sync_pending: false,
  sync_percent: 0,
  sync_description: null,
  sync_training_current: null,
  sync_current: null,

  tracking_trainings_sync: false,

  loading_last_sync: false,
  last_sync_status: true,
  last_sync_time: null,
  last_sync_data: null
};

export const fetchTrainings = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: true,
    errors: null
   };
};

export const fetchTrainingsSuccess = (state = INITIAL_STATE, action) => {
  const { responseData} = action
  console.log('trainings action responsedata', responseData)
  return { 
    ...state,
    is_loading: false,
    trainings: responseData.results,
    total_count: responseData.count,
    errors: null
   };
};

export const fetchTrainingsFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: false,
    errors: action.error,
    trainings: []
   };
};


const processProducts = (products) => {
  products = products.reduce((acc, cur) => {
    if (typeof cur.code === "boolean") cur.code = null; //filter out booleans
    if (typeof cur.name === "boolean") cur.name = null;
    return (acc = [...acc, cur]);
  }, []);
  // training_products = products.filter(
  //   (item) => !blacklisted_products_ids.includes(item.id)
  // );
  // black_listed_products = products.reduce((acc, cur) => {
  //   if (blacklisted_products_ids.includes(cur.id)) {
  //     return (acc = [...acc, cur]);
  //   }
  //   return acc;
  // }, []);
  return products;
};

export const fetchTrainingById = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: true,
    training: null,
    errors: null,
    training_products: []
   };
};

export const fetchTrainingByIdSuccess = (state = INITIAL_STATE, action) => {
  const { responseData} = action
  return { 
    ...state,
    is_loading: false,
    training: responseData,
    errors: null,
    training_products: processProducts(responseData?.products),
    free_products: responseData?.free_products,
   };
};

export const fetchTrainingByIdFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: false,
    errors: action.error,
    training: null,
    training_products: [],
    free_products: []
   };
};


export const updateTraining = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    updating_training: true,
    errors: null
   };
};

export const updateTrainingSuccess = (state = INITIAL_STATE, action) => {
  const { responseData} = action
  return { 
    ...state,
    updating_training: false,
    free_products: (responseData?.data?.free_product_ids),
    errors: null
   };
};



export const updateTrainingFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    updating_training: false,
    errors: action.error,
    training: []
   };
};


export const addFreeProduct = (state = INITIAL_STATE, action) => {
  const { item } = action
  const product = {
    ...item,
    odoo_id: item?.id,
    name: state.training_products?.find(it => it.id === item.id)?.name,
  }
  return { ...state,  free_products: [ ...state.free_products, product]}
}

export const removeFreeProduct = (state = INITIAL_STATE, action) => {
  const { item } = action
  const freeProducts = state.free_products.filter(it => it.odoo_id !== item.odoo_id)
  return { ...state, free_products: freeProducts }
}

export const editFreeProduct = (state = INITIAL_STATE, action) => {
  const {item} = action
  const products = state.free_products?.map(fp => {
    if(fp.odoo_id === item.id) {
      return {
        ...item,
        odoo_id: item?.id,
        name: state.training_products.find(it => it.id === item.id)?.name
      }
    } else {
      return fp
    }
  })
  return {...state, free_products: products}
}

export const syncTrainings = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    posting_sync: true,
    syncing: true,
    sync_date: null
  }
}

export const syncTrainingsSuccess = (state = INITIAL_STATE, action) => {
  const {
    task_id,
    message
  } = action?.responseData
  return {
    ...state,
    syncing: true,
    sync_message: message,
    posting_sync: false,
    sync_task_id: task_id ? task_id : state.task_id
  }
}

export const syncTrainingsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    posting_sync: false,
    syncing: false,
  }
}

export const trackTrainingsSync = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tracking_trainings_sync: true,
    syncing: true,
    sync_date: []
  }
}

export const trackTrainingsSyncSuccess = (state = INITIAL_STATE, action) => {
  const {  
    status,
    complete,
    pending,
    current,
    total,
    percent,
  } = action?.responseData
  return {
    ...state,
    syncing: !complete,
    sync_status: status,
    sync_total: total,
    sync_pending: pending,
    sync_percent: percent ? percent : 0,
    tracking_trainings_sync: false,
    sync_current: current,
  };
}

export const trackTrainingsSyncFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tracking_trainings_sync: false
  }
}


//============== LAST SYNC ===========
export const getLastTrainingsSync = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading_last_sync: true,
  }
}

export const getLastTrainingsSyncSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  const { data } = responseData
  return {
    ...state,
    loading_last_sync: false,
    last_sync_status: data.success,
    last_sync_time: data?.last_sync_time || null,
    last_sync_data: data
  }
}

export const getLastTrainingsSyncFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading_last_sync: true,
  }
}



export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    is_loading: false,
    training: null,
    trainings: [],
    update_training_success: null,
    updating_training: false,
    errors: null
  };
};

export const HANDLERS = {
  [Types.FETCH_TRAININGS]: fetchTrainings,
  [Types.FETCH_TRAININGS_SUCCESS]: fetchTrainingsSuccess,
  [Types.FETCH_TRAININGS_FAILURE]: fetchTrainingsFailure,

  [Types.FETCH_TRAINING_BY_ID]: fetchTrainingById,
  [Types.FETCH_TRAINING_BY_ID_SUCCESS]: fetchTrainingByIdSuccess,
  [Types.FETCH_TRAINING_BY_ID_FAILURE]: fetchTrainingByIdFailure,

  [Types.UPDATE_TRAINING]: updateTraining,
  [Types.UPDATE_TRAINING_SUCCESS]: updateTrainingSuccess,
  [Types.UPDATE_TRAINING_FAILURE]: updateTrainingFailure,

  [Types.ADD_FREE_PRODUCT]: addFreeProduct,
  [Types.REMOVE_FREE_PRODUCT]: removeFreeProduct,
  [Types.EDIT_FREE_PRODUCT]: editFreeProduct,

  [Types.SYNC_TRAININGS]: syncTrainings,
  [Types.SYNC_TRAININGS_SUCCESS]: syncTrainingsSuccess,
  [Types.SYNC_TRAININGS_FAILURE]: syncTrainingsFailure,

  [Types.TRACK_TRAININGS_SYNC]: trackTrainingsSync,
  [Types.TRACK_TRAININGS_SYNC_SUCCESS]: trackTrainingsSyncSuccess,
  [Types.TRACK_TRAININGS_SYNC_FAILURE]: trackTrainingsSyncFailure,

  [Types.GET_LAST_TRAININGS_SYNC]: getLastTrainingsSync,
  [Types.GET_LAST_TRAININGS_SYNC_SUCCESS]: getLastTrainingsSyncSuccess,
  [Types.GET_LAST_TRAININGS_SYNC_FAILURE]: getLastTrainingsSyncFailure,

  [Types.RESET_TRAINING]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
