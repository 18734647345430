import { serializeQueryParams } from "../../../util/helpers/commonHelper";
import { apiRequest } from "../apiRequest";
import { DISTRICT_ENDPOINT } from "../urls";


export const fetchDistricts = (query) => {
  let company_id = query && query.country_id
  let page = query && query.page
  let limit = query && query.limit

  const queryString = serializeQueryParams({ page, limit, company_id })

  return apiRequest(
    'GET',
    `${DISTRICT_ENDPOINT}${queryString}`
  )
}