import React, { Component } from "react";
import { Form, Row, Col, Alert, Button } from "antd";
import { withFormik, Field } from "formik";
import { InputField } from "../../../reusable-components/InputField";
import Spinner from "../../../reusable-components/Spinner";
import { validationSchemaResetForm } from "../../../util/validations";
import { history } from "../../../util/helpers/browserHistory";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import pageTitles from "../../../util/page-titles";

const FormItem = Form.Item;

class ResetPasswordForm extends Component {
  componentDidMount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const {
      handleSubmit,
      isloading,
      isRecovered,
      formerrors,
      buttonText,
      title,
      passwordSet
    } = this.props;

    let buttonState = isloading ? <Spinner color="#ffffff" /> : buttonText;

    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      layout: "vertical",
    };

    return (
      <>
        <Helmet>
          <title>{pageTitles.resetPassword}</title>
        </Helmet>
        <Form onFinish={handleSubmit} {...formItemLayout} autoComplete="off">
          <Row>
            <Col span={24}>
              <h1 style={{ marginBottom: "17px" }}>{title}</h1>
            </Col>
            {formerrors && (
              <Col span={24}>
                <Alert message={formerrors} type="error" />
              </Col>
            )}
            {isRecovered || passwordSet ? (
              <>
                <span>Your password has been {passwordSet ? 'set' : 'reset'} successfully.</span>
                <br />
                <span>
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => history.push("/")}
                  >
                    click here to login.
                  </Button>
                  </span>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Field
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your new password"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm the password"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <FormItem>
                {isRecovered || passwordSet ? null : (
                  <button type="submit" className="auth-buttons">
                    {buttonState}
                  </button>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

ResetPasswordForm.propTypes = {
  isloading: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  buttonText: "Reset",
  title: "Reset password",
};

const ResetPassword = withFormik({
  validationSchema: validationSchemaResetForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    const { match } = props;
    props.setPassword
      ? props.setPassword(
          get(match, "params.token"),
          values.password,
          props.setPasswordEmail
        )
      : props.resetPassword(
          `${get(match, "params.uid")}/${get(match, "params.token")}`,
          values.password
        );
  },

  displayName: "reset-password-form",
  enableReinitialize: false,
})(ResetPasswordForm);

export default ResetPassword;
