import axios from "axios";
import decode from "jwt-decode";
import { getLocalState } from "../app-storage";
import { BASE_URL } from "./urls";

// for endpoints that do not require tokens
export default axios.create({
  baseURL: BASE_URL,
});

export const getToken = () => {
  //get token from localstorage
  let token = getLocalState("he-admin-access");
  return token && token.access_token;
};

export const getUserDetails = () => {
  //get user details from localstorage
  let user = getLocalState("he-admin-details");
  return user;
};

export const getUserRole = () => {
  //get user details from localstorage
  return null;
};

export const getUser = () => {
  const { user_id } = decode(getToken());
  return user_id;
};

export const refreshToken = () => {};

export const axiosInstance = () => {
  var AUTH_TOKEN = getToken();
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + AUTH_TOKEN,
    },
  });
  // maybe we can add an interceptor, which checks if token is expired and refreshes it.

  return instance;
};

// custom headers
export const axiosInstanceCustomised = (token, email) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      "X-USER-TOKEN": token,
      ...(email ? { "X-USER-EMAIL": email } : {}),
    },
  });

  return instance;
};

// this is for other request types i.e application/pdf or formdata etc
export const axiosInstanceOthers = (headerType, responseType) => {
  var AUTH_TOKEN = getToken();
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: headerType,
      Authorization: "Bearer " + AUTH_TOKEN,
      "Content-Type": "multipart/form-data",
    },
    responseType: responseType,
  });

  return instance;
};
