import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  is_loading: false,
  pricelists: [],
  country: null
};

export const fetchPricelists = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: true,
    errors: null
   };
};

export const fetchPricelistsSuccess = (state = INITIAL_STATE, action) => {
  const { responseData} = action
  return { 
    ...state,
    is_loading: false,
    pricelists: responseData,
    errors: null
   };
};

export const fetchPricelistsFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: false,
    errors: action.error,
   };
};


//======== BY COUNTRY ========
export const fetchPricelistsByCountry = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: true,
    country: action.country,
    errors: null
   };
};

export const fetchPricelistsByCountrySuccess = (state = INITIAL_STATE, action) => {
  const { responseData} = action
  return { 
    ...state,
    is_loading: false,
    pricelists: responseData || [],
    errors: null
   };
};

export const fetchPricelistsByCountryFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    is_loading: false,
    pricelists: [],
    errors: action.error,
   };
};



export const resetPricelists = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    is_loading: false,
    pricelists: [],
    errors: null,
    country: null
  };
};

export const HANDLERS = {
  [Types.FETCH_PRICELISTS]: fetchPricelists,
  [Types.FETCH_PRICELISTS_SUCCESS]: fetchPricelistsSuccess,
  [Types.FETCH_PRICELISTS_FAILURE]: fetchPricelistsFailure,

  [Types.FETCH_PRICELISTS_BY_COUNTRY]: fetchPricelistsByCountry,
  [Types.FETCH_PRICELISTS_BY_COUNTRY_SUCCESS]: fetchPricelistsByCountrySuccess,
  [Types.FETCH_PRICELISTS_BY_COUNTRY_FAILURE]: fetchPricelistsByCountryFailure,

  [Types.RESET_PRICELISTS]: resetPricelists,
};

export default createReducer(INITIAL_STATE, HANDLERS);
