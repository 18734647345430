import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchTrainings: ["query"],
    fetchTrainingsSuccess: ["responseData"],
    fetchTrainingsFailure: ["error"],

    fetchTrainingById: ["id"],
    fetchTrainingByIdSuccess: ["responseData"],
    fetchTrainingByIdFailure: ["error"],

    updateTraining: ["id", "data"],
    updateTrainingSuccess: ["responseData"],
    updateTrainingFailure: ["error"],

    updateFreeProducts: ["id","payload"],
    updateFreeProductsSuccess: ["responseData"],
    updateFreeProductsFailure: ["error"],

    addFreeProduct: ["item"],
    removeFreeProduct: ["item"],
    editFreeProduct: ["item"],

    syncTrainings: ["query"],
    syncTrainingsSuccess: ["responseData"],
    syncTrainingsFailure: ["error"],

    trackTrainingsSync: ["taskId","query"],
    trackTrainingsSyncSuccess: ["responseData"],
    trackTrainingsSyncFailure: ["error"],

    getLastTrainingsSync: null,
    getLastTrainingsSyncSuccess: ["responseData"],
    getLastTrainingsSyncFailure: ["error"],

    resetTraining: null,
  },
  {}
);