import { createReducer } from "reduxsauce";
import Types from './actionTypes'

export const INITIAL_STATE = {
  errors: null,
  isLoading: false,
  districts: [],
  totalCount: 0,
}

export const fetchDistricts = (state=INITIAL_STATE, action) => {
  return {...state, errors: null, isLoading: true}
}

export const fetchDistrictsSuccess = (state=INITIAL_STATE, action) => {
  return {...state, districts: action.responseData, totalCount: action.totalCount, isLoading: false}
}

export const fetchDistrictsFailure = (state=INITIAL_STATE, action) => {
  return {...state, errors: action.error, isLoading: false}
}

export const resetDistricts = (state=INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    error: null,
    totalCount: 0,
    districts: []
  }
}

export const HANDLERS = {
  [Types.FETCH_DISTRICTS]: fetchDistricts,
  [Types.FETCH_DISTRICTS_SUCCESS]: fetchDistrictsSuccess,
  [Types.FETCH_DISTRICTS_FAILURE]: fetchDistrictsFailure,

  [Types.RESET_DISTRICTS]: resetDistricts
}

export default createReducer(INITIAL_STATE, HANDLERS)