import { serializeQueryParams } from "../../../util/helpers/commonHelper";
import { apiRequest } from "../apiRequest";
import { CLUSTER_ENDPOINT } from "../urls";


export const fetchClusters = (query) => {
  let company_id = query && query.country_id
  let page = query && query.page
  let limit = query && query.limit
  const queryString = serializeQueryParams({ company_id, page, limit })
  
  return apiRequest(
    "GET",
    `${CLUSTER_ENDPOINT}${queryString}`
  )
}