import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  payment_terms: [],
};

export const fetchPaymentTerms = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true, payment_terms: [] };
};

export const fetchPaymentTermsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    payment_terms: action.responseData,
    isloading: false,
    errors: null
  };
};

export const fetchPaymentTermsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error, payment_terms: [] };
};

export const resetPaymentTerms = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: null, payment_terms: [] };
};


export const HANDLERS = {
  [Types.RESET_PAYMENT_TERMS]: resetPaymentTerms,
  [Types.FETCH_PAYMENT_TERMS]: fetchPaymentTerms,
  [Types.FETCH_PAYMENT_TERMS_SUCCESS]: fetchPaymentTermsSuccess,
  [Types.FETCH_PAYMENT_TERMS_FAILURE]: fetchPaymentTermsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
