import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  payment_methods: [],
};

export const fetchPaymentMethods = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchPaymentMethodsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    payment_methods: action.responseData,
    isloading: false,
  };
};

export const fetchPaymentMethodsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const HANDLERS = {
  [Types.FETCH_PAYMENT_METHODS]: fetchPaymentMethods,
  [Types.FETCH_PAYMENT_METHODS_SUCCESS]: fetchPaymentMethodsSuccess,
  [Types.FETCH_PAYMENT_METHODS_FAILURE]: fetchPaymentMethodsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
