/* Base Urls */
export const BASE_URL = process.env.REACT_APP_BASE_URL;

/* Auth endpoints */
export const LOGIN_ENDPOINT = "/user/admin/login";
export const SIGN_UP_ENDPOINT = "users/signup";
export const FORGOT_PASSWORD_ENDPOINT = "/user/admin/reset-password";
export const RESET_PASSWORD_ENDPOINT = "/user/admin/change-password";
export const CHANGE_PASSWORD_ENDPOINT = "users/change";
export const REFRESH_TOKEN_ENDPOINT = "refresh/token";

/* user account endpoints */
export const USERS_ENDPOINT = "/user/ches";
export const USER_PROFILE_ENDPOINT = "/user/auth/profile/";
export const RESET_NEW_ACCOUNT_ENDPOINT = "";

/* country endpoints */
export const COUNTRIES_ENDPOINT = "/country";

/* staff endpoints */
export const STAFF_ENDPOINT = "/user/admin/users";
export const CREATE_STAFF_ENDPOINT = "/user/admin/create-user";
export const SET_STAFF_PASSWORD_VALID_LINK_ENDPOINT = "/user/admin/activate/";
export const SET_STAFF_PASSWORD = "/user/admin/change-password?set-password=true";

/* orders endpoints */
export const ORDERS_ENDPOINT = "/orders";

/* payments endpoints */
export const PAYMENTS_ENDPOINT = "/payments"

/* payment methods endpoints */
export const PAYMENT_METHODS_ENDPOINT = "/payments/payment_methods/";

/* user group endpoints */
export const USER_GROUPS_ENDPOINT = "/user-groups/";

export const TRAININGS_ENDPOINT = "/trainings"

/* cluster endpoints */
export const CLUSTER_ENDPOINT = "/clusters/"

/* district endpoints */
export const DISTRICT_ENDPOINT = "/districts/"

/* promotions endpoints */
export const PROMOTIONS_ENDPOINT = "/promotions"

export const SAVE_DEVICE_TOKEN = "/user/device/"