import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getStaffRequest: [],
    getStaffSuccess: ["responseData"],
    getStaffFailure: ["error"],

    deleteStaffRequest: ['id'],
    deleteStaffSuccess: ["responseData"],
    deleteStaffFailure: ["error"],

    addStaffRequest: ['data'],
    addStaffSuccess: ["responseData"],
    addStaffFailure: ["error"],

    editStaffRequest: ['id', 'data'],
    editStaffSuccess: ["responseData"],
    editStaffFailure: ["error"],

    validateSetPasswordStaffRequest: ['uuid', 'token'],
    validateSetPasswordStaffSuccess: ["responseData"],
    validateSetPasswordStaffFailure: ["error"],

    setPasswordStaffRequest: ['token', 'email', 'password'],
    setPasswordStaffSuccess: ["responseData"],
    setPasswordStaffFailure: ["error"],
  },
  {}
);
