import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_CLUSTERS
  FETCH_CLUSTERS_SUCCESS
  FETCH_CLUSTERS_FAILURE
  RESET_CLUSTERS
  `,
  {}
);