import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/users/actions";
// import { Creators as PaginationCreators } from "../../redux/pagination/actions"
import {
	createUser as createUserApi,
	updateUser as updateUserApi,
	fetchUsers as fetchUsersApi,
	getUser as getUserApi,
	resetChePassword as resetChePasswordApi,
	deactivateUser as deactivateUserApi,
	generateChesPasswords as generateChesPasswordsApi,
	refreshUsers as refreshUsersApi,
  generateChesActivationQrcode as generateChesActivationQrcodeApi,
  trackUserSync as trackUserSyncApi,
  getLastSync as getLastSyncApi
} from "../../api/users";
import { history } from "../../../util/helpers/browserHistory";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { downloadFile } from "../../../util/helpers/commonHelper";
import { rollbar } from "../../../util/configs";

export function* createUser(actions) {
	try {
		const { payload } = actions;
		if (payload.active === "active") {
			payload.active = true;
		} else {
			payload.active = false;
		}
		if (payload.active) delete payload.deactivated;

		const response = yield call(createUserApi, payload);
		const resp = response && response.data;
		yield put(Creators.createUserSuccess(resp));
		OpenNotificationWithIcon(
			"success",
			"User Account",
			"Account has been created successfully!"
		);

		history.push("/app/users");
	} catch (error) {
		rollbar.error(`Api call create user error ${error}`)
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"User Account",
			`Failed to create account! ${errorsFound && errorsFound.message}`
		);
		yield put(Creators.createUserFailure(errorsFound));
	}
}

export function* updateUser(actions) {
	try {
		const { id, payload } = actions;
		if (payload.active === "active") {
			payload.active = true;
		} else {
			payload.active = false;
		}
		if (payload.active) delete payload.deactivated;
    rollbar.info(`Update user request ${payload}`)
		const response = yield call(updateUserApi, id, payload);
		const resp = response && response.data;
		yield put(Creators.updateUserSuccess(resp && resp.data));
    rollbar.info(`Update user response: ${resp?.data}`)
		OpenNotificationWithIcon(
			"success",
			"User Update",
			"User has been updated successfully!"
		);
		history.push("/app/users");
	} catch (error) {
		rollbar.error(`Update user error ${error}`)
		OpenNotificationWithIcon("error", "User Update", "Failed to update the user!");
		let errorsFound = error.response && error.response.data;
		yield put(Creators.updateUserFailure(errorsFound));
	}
}

export function* getUser(actions) {
	try {
		const { id } = actions;
		const response = yield call(getUserApi, id);
		const resp = response && response.data;

		yield put(Creators.getUserSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call get user error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getUserFailure(errorsFound));
	}
}

export function* resetChePassword(actions) {
	try {
		const { id } = actions;
		const response = yield call(resetChePasswordApi, id);
		const resp = response && response.data;

		yield put(Creators.resetChePasswordSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call reset che user password error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.resetChePasswordFailure(errorsFound));
	}
}

export function* fetchUsers(actions) {
	try {
		const { query } = actions;
		if (query?.status !== undefined && query?.status !== null) {
			if (query.status === "activated") query.is_active = true;
			else query.is_active = false;
		}
		const response = yield call(fetchUsersApi, query);
		const resp = response?.data;
		yield put(Creators.fetchUsersSuccess(resp?.results, resp?.count));
	} catch (error) {
		rollbar.error(`Api call fetch users error ${error}`)
		// let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon("error", "Get Users", "Failed to fetch users!");
		yield put(Creators.fetchUsersFailure("Failed to fetch users"));
	}
}

export function* refreshUsers(actions) {
	try {
		const { query } = actions
    rollbar.info(`Sync users list: ${query}`)
		const response = yield call(refreshUsersApi, query)
    console.log('response', response)
		yield put(Creators.refreshUsersSuccess(response))
		//yield put(PaginationCreators.reset())
		//yield put(Creators.fetchUsers({page: 1, limit: 50, country: query.country, district: query.district}))
	} catch (error) {
		rollbar.error(`Api call refresh user error ${error}`)
		OpenNotificationWithIcon("error", "Refresh Users", "Failed to refresh users!");
		yield put(Creators.refreshUsersFailure());
	}
}

export function* syncUsers(actions) {
	try {
		const { query } = actions
    rollbar.info(`Sync users list: ${query}`)
		const response = yield call(refreshUsersApi, query)
    const responseData = response?.data
    const data = {
      message: responseData?.message,
      percent: responseData?.data?.result?.percent || 0,
      status: responseData?.data?.status || 'init',
      task_id: responseData?.task_id || responseData?.data?.task_id,
      current: responseData?.data?.result?.current,
      description: responseData?.data?.result?.description,
      pending: responseData?.data?.result?.pending,
      total: responseData?.data?.result?.total || 0
    }

    console.log('sync response', responseData)
    console.log('sync redux state', data)
		yield put(Creators.syncUsersSuccess(data))
		//yield put(PaginationCreators.reset())
		//yield put(Creators.fetchUsers({page: 1, limit: 50, country: query.country, district: query.district}))
	} catch (error) {
		rollbar.error(`Api call refresh user error ${error}`)
		OpenNotificationWithIcon("error", "Refresh Users", "Failed to refresh users!");
		yield put(Creators.syncUsersFailure());
	}
}

export function* trackUserSync(actions) {
	try {
		const { taskId, query } = actions
    rollbar.info(`Track user sync: ${taskId}`)
		const response = yield call(trackUserSyncApi, taskId)
    const responseData = response?.data

    const data = {
      status: responseData?.state,
      complete: responseData?.complete,
      success: responseData?.success,
      pending: responseData?.progress?.pending,
      current: responseData?.progress?.current,
      total: responseData?.progress?.total,
      percent: responseData?.progress?.percent,
      description: responseData?.progress?.description,
    }
		yield put(Creators.trackUserSyncSuccess(data))
    if(data?.complete) {
      OpenNotificationWithIcon('success', 'User Sync Successful')
      yield put(Creators.fetchUsers({page: 1, limit: 50, country: query.country, district: query.district}))
    }
		//yield put(PaginationCreators.reset())
		//yield put(Creators.fetchUsers({page: 1, limit: 50, country: query.country, district: query.district}))
	} catch (error) {
		rollbar.error(`Api call refresh user error ${error}`)
		OpenNotificationWithIcon("error", "Refresh Users", "Failed to refresh users!");
		yield put(Creators.trackUserSyncFailure());
	}
}

export function* deactivateUser(actions) {
	try {
		const { id } = actions;
    rollbar.info(`Deactivate user request: ${{userId: id}}`)
		yield call(deactivateUserApi, id, { is_active: false });
		const message = "User has been deactivated successfully.";
		yield put(Creators.deactivateUserSuccess(message));
    rollbar.info(`Deactivate user response: ${message}`)
		OpenNotificationWithIcon("success", "Deactive User", message);
	} catch (error) {
		rollbar.error(`Api call deactivate user error: ${error}`)
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"Deactive User",
			errorsFound && errorsFound.message
		);
		yield put(Creators.deactivateUserFailure(errorsFound && errorsFound.message));
	}
}

export function* generateChesPasswords(actions) {
	try {
		const data = actions.payload;
		const response = yield call(generateChesPasswordsApi, data);
		const responseData = response && response.data;
		yield put(Creators.generateChesPasswordsSuccess(responseData));
		let fileName = "che-user-passwords.csv";
		let fileType = "text/csv"
		downloadFile(responseData, fileName, fileType)
	} catch (error) {
		rollbar.error(`Api call generate ches passwords error ${error}`)
		OpenNotificationWithIcon("error", "Generate passwords", "Failed to generate passwords!");
		yield put(Creators.generateChesPasswordsFailure("Failed to generate passwords"));
	}
}

export function* generateChesActivationQrcode(actions) {
	try {
		const payload = actions.payload;
		const response = yield call(generateChesActivationQrcodeApi, payload);
		const responseData = response && response.data;
		yield put(Creators.generateChesActivationQrcodeSuccess(responseData?.qr_code));
	} catch (error) {
    const errorData = error?.response?.data || {}
    const errorMessage = errorData?.message || errorData?.error || 'request failed'
		rollbar.error(`Api call generate ches activation qr code error ${error}`)
		OpenNotificationWithIcon("error", "Generate QR code", errorMessage);
		yield put(Creators.generateChesActivationQrcodeFailure(errorMessage));
	}
}

export function* getLastSync(actions) {
	try {
		const response = yield call(getLastSyncApi);
		const responseData = response?.data;
		yield put(Creators.getLastSyncSuccess(responseData));
    
	} catch (error) {
    const errorData = error?.response?.data || {}
    const errorMessage = errorData?.message || errorData?.error || 'request failed'
		rollbar.error(`Api call get users last sync error ${error}`)
		yield put(Creators.getLastSyncFailure(errorMessage));
	}
}

export function* watchFetchUsers() {
	yield takeLatest(Types.FETCH_USERS, fetchUsers);
}

export function* watchGetUser() {
	yield takeEvery(Types.GET_USER, getUser);
}

export function* watchResetChePassword() {
	yield takeEvery(Types.RESET_CHE_PASSWORD, resetChePassword);
}

export function* watchCreateUser() {
	yield takeEvery(Types.CREATE_USER, createUser);
}

export function* watchUpdateUser() {
	yield takeEvery(Types.UPDATE_USER, updateUser);
}

export function* watchDeactivateUser() {
	yield takeLeading(Types.DEACTIVATE_USER, deactivateUser);
}

export function* watchGenerateChesPasswords() {
	yield takeLeading(Types.GENERATE_CHES_PASSWORDS, generateChesPasswords)
}

export function* watchRefreshUsers() {
	yield takeLeading(Types.REFRESH_USERS, refreshUsers)
}

export function* watchGenerateChesActivationQrcode() {
  yield takeLatest(Types.GENERATE_CHES_ACTIVATION_QRCODE, generateChesActivationQrcode)
}

export function* watchSyncUsers() {
  yield takeLatest(Types.SYNC_USERS, syncUsers)
}

export function* watchTrackUserSync() {
  yield takeLatest(Types.TRACK_USER_SYNC, trackUserSync)
}

export function* watchGetLastSync() {
  yield takeLatest(Types.GET_LAST_SYNC, getLastSync)
}