import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/orders/actions";
import {
	fetchOrders as fetchOrdersApi,
	fetchOrderDetails as fetchOrderDetailsApi,
} from "../../api/orders";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchOrders(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchOrdersApi, query);
		const resp = response && response.data;
		yield put(Creators.fetchOrdersSuccess(resp && resp.results, resp && resp.count));
	} catch (error) {
		rollbar.error(`Api call fetch orders error ${error}`)
		// let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon("error", "Get Orders", "Failed to fetch orders!");
		yield put(Creators.fetchOrdersFailure());
	}
}

export function* watchFetchOrders() {
	yield takeLatest(Types.FETCH_ORDERS, fetchOrders);
}

export function* fetchOrderDetails(actions) {
	try {
		const { id } = actions;
		const response = yield call(fetchOrderDetailsApi, id);
		yield put(Creators.fetchOrderDetailSuccess(response.data));
	} catch (error) {
		rollbar.error(`Api call fetch order details error ${error}`)
		OpenNotificationWithIcon(
			"error",
			"Get Order detail",
			"Failed to fetch order details!"
		);
		yield put(Creators.fetchOrderDetailFailure());
	}
}

export function* watchFetchOrderDetails() {
	yield takeLatest(Types.FETCH_ORDER_DETAIL, fetchOrderDetails);
}
