import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/staff/actions";
import {
	getStaff as getStaffsApi,
	deleteStaff as deleteStaffsApi,
	addStaff as addStaffsApi,
	editStaff as editStaffsApi,
	validateSetPasswordLink as validateSetPasswordLinkApi,
	setPassword as setPasswordApi
} from "../../api/staff";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* getStaffs(actions) {
	try {
		const response = yield call(getStaffsApi);
		yield put(Creators.getStaffSuccess(response.data));
	} catch (e) {
		rollbar.error(`Api call fetch staffs error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot Fetch Staff";
		OpenNotificationWithIcon("error", "Fetching Staff Failed!", message);
		yield put(Creators.getStaffFailure(message));
	}
}

export function* deleteStaff(actions) {
	try {
		const { id } = actions;
		const response = yield call(deleteStaffsApi, id);
		yield put(Creators.deleteStaffSuccess(response.data));

		window.location.reload();
	} catch (e) {
		rollbar.error('Api call delete staff error', e)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot delete Staff";
		OpenNotificationWithIcon("error", "Deleting Staff Failed!", message);
		yield put(Creators.deleteStaffFailure(message));
	}
}

export function* addStaff(actions) {
	try {
		const { data } = actions;
    rollbar.info(`Add new staff request: ${data}`)
		const response = yield call(addStaffsApi, { ...data });
		yield put(Creators.addStaffSuccess(response.data));
    rollbar.info(`Add new staff response: ${response?.data}`)
		window.location.href = "/app/staff"
		OpenNotificationWithIcon(
			"success",
			"Success",
			"Staff created successfully"
		);
	} catch (e) {
		rollbar.error(`Api call add staff error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot add Staff";
		OpenNotificationWithIcon("error", "Add Staff Failed!", message);
		yield put(Creators.addStaffFailure(message));
	}
}

export function* editStaff(actions) {
	try {
		const { id, data } = actions;
		const response = yield call(editStaffsApi, id, { ...data });
		yield put(Creators.editStaffSuccess(response.data));

		window.location.href = "/app/staff"
		OpenNotificationWithIcon(
			"success",
			"Success",
			"Staff edited successfully"
		);
	} catch (e) {
		rollbar.error(`Api call edit staff error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot edit Staff";
		OpenNotificationWithIcon("error", "Edit Staff Failed!", message);
		yield put(Creators.editStaffFailure(message));
	}
}

export function* validateStaffSetPasswordLink(actions) {
	try {
		const { uuid, token } = actions;
		const response = yield call(validateSetPasswordLinkApi, uuid, token);
		yield put(Creators.validateSetPasswordStaffSuccess(response.data.email));

	} catch (e) {
		rollbar.error(`Api call validate staff set password error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot verify link";
		yield put(Creators.validateSetPasswordStaffFailure(message));
	}
}

export function* setStaffPassword(actions) {
	try {
		const { token, email, password } = actions;
		const response = yield call(setPasswordApi, token, email, password);
		yield put(Creators.setPasswordStaffSuccess(response.data));
	} catch (e) {
		rollbar.error(`Api call set staff password error ${e}`)
		const message =
			(e && e.response.data && e.response.data.message) || "Cannot set password";
			OpenNotificationWithIcon("error", "Staff Password!", message);
		yield put(Creators.setPasswordStaffFailure(message));
	}
}

export function* watchFetchStaff() {
	yield takeLeading(Types.GET_STAFF_REQUEST, getStaffs);
}

export function* watchDeleteStaff() {
	yield takeLeading(Types.DELETE_STAFF_REQUEST, deleteStaff);
}

export function* watchAddStaff() {
	yield takeLeading(Types.ADD_STAFF_REQUEST, addStaff);
}

export function* watchEditStaff() {
	yield takeLeading(Types.EDIT_STAFF_REQUEST, editStaff);
}

export function* watchVaalidateSetPasswordLinkStaff() {
	yield takeLeading(Types.VALIDATE_SET_PASSWORD_STAFF_REQUEST, validateStaffSetPasswordLink);
}

export function* watchSetStaffPassword() {
	yield takeLeading(Types.SET_PASSWORD_STAFF_REQUEST, setStaffPassword);
}