import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/country/actions";
import {
	fetchCountries as fetchCountriesApi,
	updateCountry as updateCountryApi,
} from "../../api/country";
import { rollbar } from "../../../util/configs";

export function* fetchCountries(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchCountriesApi, query);
		const resp = response && response.data;

		yield put(Creators.fetchCountriesSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call fetch countries error ${error}`)
		yield put(Creators.fetchCountriesFailure());
	}
}

export function* updateCountry(actions) {
	try {
		const { id, data } = actions;
		const response = yield call(updateCountryApi, id, data);
		const resp = response && response.data;

		yield put(Creators.updateCountrySuccess(resp));
	} catch (error) {
		rollbar.error(`Api call update country error ${error}`)
		yield put(Creators.updateCountryFailure());
	}
}

export function* watchFetchCountries() {
	yield takeLatest(Types.FETCH_COUNTRIES, fetchCountries);
}

export function* watchUpdateCountry() {
	yield takeLatest(Types.UPDATE_COUNTRY, updateCountry);
}
