import React from "react";
import { Layout, Row, Col } from "antd";
import "../../styles/header.less"; 
import WebHeader from "./components/WebHeader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Creators } from "../../services/redux/auth/actions";

const { Header } = Layout;

class PageHeader extends React.Component {
  render() {
    return (
      <>
        <Header className="header">
          <div id="main">
            <Row>
              <Col xs={24} sm={24} md={24}>
                <WebHeader logout={this.props.logoutMethod} {...this.props} />
              </Col>
            </Row>
          </div>
        </Header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (user_id) => {},
    logout: () => {
      dispatch(Creators.logout());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHeader));
