import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import "./styles/main.less";
import { rollbarConfig } from "./util/configs";

export const ErrorDisplay = ({ error, resetError }) => ( // <-- props passed to fallbackUI component
  <div>
    <h1>A following error has occured:</h1>
    <p>{error.toString()}</p>
  </div>
);


ReactDOM.render(
	<RollbarProvider config={rollbarConfig}>
		<RollbarErrorBoundary fallbackUI={ErrorDisplay}>
			<Provider store={store}>
				<App />
			</Provider>
		</RollbarErrorBoundary>

	</RollbarProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
