import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_STAFF_REQUEST
  GET_STAFF_SUCCESS
  GET_STAFF_FAILURE

  DELETE_STAFF_REQUEST
  DELETE_STAFF_SUCCESS
  DELETE_STAFF_FAILURE

  ADD_STAFF_REQUEST
  ADD_STAFF_SUCCESS
  ADD_STAFF_FAILURE

  EDIT_STAFF_REQUEST
  EDIT_STAFF_SUCCESS
  EDIT_STAFF_FAILURE

  VALIDATE_SET_PASSWORD_STAFF_REQUEST
  VALIDATE_SET_PASSWORD_STAFF_SUCCESS
  VALIDATE_SET_PASSWORD_STAFF_FAILURE

  SET_PASSWORD_STAFF_REQUEST
  SET_PASSWORD_STAFF_SUCCESS
  SET_PASSWORD_STAFF_FAILURE
`,
  {}
);
