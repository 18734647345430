const pageTitle = 'HE Products Admin - '

const pageTitles = {
  listChes: `${pageTitle}ches`,
  cheView: `${pageTitle}che`,
  companyList: `${pageTitle}companies`,
  orderList: `${pageTitle}orders`,
  orderView: `${pageTitle}order info`,
  staffList: `${pageTitle}staff list`,
  staffEdit: `${pageTitle}edit staff`,
  staffAdd: `${pageTitle}add staff`,
  userGroupList: `${pageTitle}user groups`,
  trainingsList: `${pageTitle}trainings`,
  userGroupView: `${pageTitle}user group details`,
  signIn: `${pageTitle}signin`,
  forgotPassword: `${pageTitle}forgot password`,
  resetPassword: `${pageTitle}reset password`,
  promotions: `${pageTitle}promotions`,
  paymentsList: `${pageTitle}payments`
}

export default pageTitles