import { combineReducers } from "redux";
import auth from "./services/redux/auth/reducers";
import users from "./services/redux/users/reducers";
import orders from "./services/redux/orders/reducers";
import country from "./services/redux/country/reducers";
import staff from "./services/redux/staff/reducers";
import payment_methods from "./services/redux/payment_methods/reducers";
import user_groups from "./services/redux/user_groups/reducers";
import pagination from "./services/redux/pagination/reducers";
import districts from './services/redux/districts/reducers'
import clusters from './services/redux/clusters/reducers'
import promotion from "./services/redux/promotions/reducers"
import accounting from "./services/redux/accounting/reducers"
import payments from "./services/redux/payments/reducers"
import trainings from "./services/redux/trainings/reducers"
import pricelist from "./services/redux/pricelist/reducers"

const rootReducer = combineReducers({
	auth,
	users,
	orders,
	country,
	staff,
	payment_methods,
	user_groups,
	pagination,
	districts,
	clusters,
	promotion,
	accounting,
  payments,
  trainings,
  pricelist
});

export default rootReducer;
