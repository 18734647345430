import { createTypes } from "reduxsauce";

export default createTypes(
	`
  FETCH_PAYMENT_TERMS
  FETCH_PAYMENT_TERMS_SUCCESS
  FETCH_PAYMENT_TERMS_FAILURE

  RESET_PAYMENT_TERMS
`,
	{}
);
