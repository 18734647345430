import React, { Component } from "react";
import { Form, Row, Col, Alert } from "antd";
import { withFormik, Field } from "formik";
import { InputField } from "../../../reusable-components/InputField";
import Spinner from "../../../reusable-components/Spinner";
import { validationSchemaForgotForm } from "../../../util/validations";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import pageTitles from "../../../util/page-titles";

const FormItem = Form.Item;

class ForgotPasswordForm extends Component {
  componentDidMount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { handleSubmit, isloading, linkSent, formerrors } = this.props;

    let buttonState = isloading ? <Spinner color="#ffffff" /> : "Send Reset Link";

    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      layout: "vertical",
    };

    return (
      <>
        <Helmet>
          <title>{pageTitles.resetPassword}</title>
        </Helmet>
        <Form onFinish={handleSubmit} {...formItemLayout} autoComplete="off">
          <Row>
            <Col span={24}>
              <h1 style={{ marginBottom: "17px" }}>Reset Password</h1>
            </Col>
            {formerrors && (
              <Col span={24}>
                <Alert message={formerrors} type="error" />
              </Col>
            )}
            {linkSent ? (
              <>
                <span>A password reset link has been sent. </span>
                <br />
                <span>
                  Please check your email and click on the link to reset your
                  password.
                </span>
              </>
            ) : (
              <Col span={24}>
                <Field
                  name="email"
                  label="Email Address"
                  placeholder="Enter your email address"
                  size="large"
                  component={InputField}
                  formerrors={formerrors}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <FormItem>
                {linkSent ? null : (
                  <button type="submit" className="auth-buttons">
                    {buttonState}
                  </button>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

ForgotPasswordForm.propTypes = {
  isloading: PropTypes.bool,
};

const ForgotPassword = withFormik({
  validationSchema: validationSchemaForgotForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.forgotPassword(values.email);
  },

  displayName: "forgot-password-form",
  enableReinitialize: false,
})(ForgotPasswordForm);

export default ForgotPassword;
