import { serializeQueryParamsOnNull } from "../../../util/helpers/commonHelper";
import { apiRequest } from "../apiRequest";

export const fetchPricelists = (query) => {
  const queryStr = serializeQueryParamsOnNull(query)
	return apiRequest("GET", `/pricelists/${queryStr}`);
};


export const fetchPricelistsByCountry = (countryId) => {
  return apiRequest(
    "GET",
    `/country/${countryId}/country_pricelists/`
  )
}
