import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/payments/actions";
import {
	fetchPayments as fetchPaymentsApi,
	fetchPaymentDetails as fetchPaymentDetailsApi,
} from "../../api/payments";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchPayments(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchPaymentsApi, query);
		const resp = response && response.data;
		yield put(Creators.fetchPaymentsSuccess(resp && resp.results, resp && resp.count));
	} catch (error) {
		rollbar.error(`Api call fetch Payments error ${error}`)
		// let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon("error", "Get Payments", "Failed to fetch Payments!");
		yield put(Creators.fetchPaymentsFailure());
	}
}

export function* watchFetchPayments() {
	yield takeLatest(Types.FETCH_PAYMENTS, fetchPayments);
}

export function* fetchPaymentDetails(actions) {
	try {
		const { id } = actions;
		const response = yield call(fetchPaymentDetailsApi, id);
		yield put(Creators.fetchPaymentDetailSuccess(response.data));
	} catch (error) {
		rollbar.error(`Api call fetch Payment details error ${error}`)
		OpenNotificationWithIcon(
			"error",
			"Get Payment detail",
			"Failed to fetch Payment details!"
		);
		yield put(Creators.fetchPaymentDetailFailure());
	}
}

export function* watchFetchPaymentDetails() {
	yield takeLatest(Types.FETCH_PAYMENT_DETAIL, fetchPaymentDetails);
}
