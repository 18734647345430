import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchPaymentMethods: null,
    fetchPaymentMethodsSuccess: ["responseData"],
    fetchPaymentMethodsFailure: ["error"],
  },
  {}
);
