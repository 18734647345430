import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchUsers: ["query"],
    fetchUsersSuccess: ["responseData", "totalCount"],
    fetchUsersFailure: ["error"],

    refreshUsers: ["query"],
    refreshUsersSuccess: ["responseData"],
    refreshUsersFailure: ["error"],

    syncUsers: ['query'],
    syncUsersSuccess: ['responseData'],
    syncUsersFailure: ['error'],

    trackUserSync: ['taskId','query'],
    trackUserSyncSuccess: ['responseData'],
    trackUserSyncFailure: ['error'],

    createUser: ["payload"],
    createUserSuccess: ["responseData"],
    createUserFailure: ["error"],

    getUser: ["id"],
    getUserSuccess: ["responseData"],
    getUserFailure: ["error"],

    updateUser: ["id", "payload"],
    updateUserSuccess: ["responseData"],
    updateUserFailure: ["error"],

    resetChePassword: ["id"],
    resetChePasswordSuccess: ["responseData"],
    resetChePasswordFailure: ["error"],

    deactivateUser: ["id"],
    deactivateUserSuccess: ["responseData"],
    deactivateUserFailure: ["error"],

    resetPasswordGeneration: null,

    setFilter: ['filter'],
    resetFilter: null,

    generateChesPasswords: ["payload"],
    generateChesPasswordsSuccess: ["responseData"],
    generateChesPasswordsFailure: ["error"],

    generateChesActivationQrcode: ["payload"],
    generateChesActivationQrcodeSuccess: ["responseData"],
    generateChesActivationQrcodeFailure: ["error"],

    getLastSync: [],
    getLastSyncSuccess: ["responseData"],
    getLastSyncFailure: ["error"],

    reset: null,
  },
  {}
);
