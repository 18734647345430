import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_ORDERS
  FETCH_ORDERS_SUCCESS
  FETCH_ORDERS_FAILURE

  FETCH_ORDER_DETAIL
  FETCH_ORDER_DETAIL_SUCCESS
  FETCH_ORDER_DETAIL_FAILURE

  RESET

`,
  {}
);
