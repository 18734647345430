import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/pricelist/actions";
import { 
  fetchPricelists as fetchPricelistsApi,
  fetchPricelistsByCountry as fetchPricelistsByCountryApi
} from "../../api/pricelist"

import { rollbar } from "../../../util/configs";

export function* fetchPricelists(actions) {
  const { query } = actions
	try {
		const response = yield call(fetchPricelistsApi, query);
		yield put(Creators.fetchPricelistsSuccess(response?.data));
	} catch (e) {
		rollbar.error(`Api call fetch price-list error ${e}`)
		const message =
			(e && e?.response?.data && e.response?.data?.message) ||
			"Cannot Fetch Price-lists";
		yield put(Creators.fetchPricelistsFailure(message));
	}
}

export function* fetchPricelistsByCountry(action) {
  try {
    const { country } = action
    const response = yield call(fetchPricelistsByCountryApi, country.id)
    console.log('response data', response?.data?.data)
    yield put(Creators.fetchPricelistsByCountrySuccess(response?.data?.data))
  } catch(e) {
    yield put(Creators.fetchPricelistsByCountryFailure(e))
  }
}

export function* watchFetchPricelists() {
  yield takeLatest(Types.FETCH_PRICELISTS, fetchPricelists)
}

export function* watchFetchPricelistsByCountry() {
  yield takeLatest(Types.FETCH_PRICELISTS_BY_COUNTRY, fetchPricelistsByCountry)
}
