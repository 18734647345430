import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_PROMOTIONS
  FETCH_PROMOTIONS_SUCCESS
  FETCH_PROMOTIONS_FAILURE

  FETCH_PROMOTION
  FETCH_PROMOTION_SUCCESS
  FETCH_PROMOTION_FAILURE

  CREATE_PROMOTION
  CREATE_PROMOTION_SUCCESS
  CREATE_PROMOTION_FAILURE

  UPDATE_PROMOTION
  UPDATE_PROMOTION_SUCCESS
  UPDATE_PROMOTION_FAILURE

  DELETE_PROMOTION
  DELETE_PROMOTION_SUCCESS
  DELETE_PROMOTION_FAILURE

  RESET_PROMOTIONS

`,
  {}
);
