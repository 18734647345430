import React, { Component } from "react";
import { Form, Button, Row, Col, Alert } from "antd";
import { withFormik, Field } from "formik";
import { InputField } from "../../../reusable-components/InputField";
import Spinner from "../../../reusable-components/Spinner";
import { validationSchemaLoginForm } from "../../../util/validations";
import { history } from "../../../util/helpers/browserHistory";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'
import pageTitles from "../../../util/page-titles";

const FormItem = Form.Item;

class LoginForm extends Component {
  componentDidMount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { handleSubmit, isloading, formerrors } = this.props;

    let buttonState = isloading ? <Spinner color="#ffffff" /> : "Sign in";

    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      layout: "vertical",
    };

    return (
      <>
        <Helmet>
          <title>{pageTitles.signIn}</title>
        </Helmet>
        <Form onFinish={handleSubmit} {...formItemLayout} autoComplete="off">
          <Row>
            <Col span={24}>
              <h1 style={{ marginBottom: "17px" }}>Sign In</h1>
            </Col>
            {formerrors && (
              <Col span={24}>
                <Alert message={formerrors} type="error" />
              </Col>
            )}
            <Col span={24}>
              <Field
                name="email"
                label="Email Address"
                placeholder="Enter your email address"
                size="large"
                component={InputField}
              />
            </Col>
            <Col span={24}>
              <Field
                type="password"
                name="password"
                label="Password"
                placeholder="Enter the password"
                size="large"
                component={InputField}
              />
            </Col>
            <Button
              type="link"
              className="auth-link"
              onClick={() => history.push("/forgot/password")}
            >
              Forgot Password?
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem>
                <button type="submit" className="auth-buttons">
                  {buttonState}
                </button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}


LoginForm.propTypes = {
  isloading: PropTypes.bool,
};

const Login = withFormik({
  validationSchema: validationSchemaLoginForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.loginUser(values.email, values.password);
  },

  displayName: "signin-form",
  enableReinitialize: false,
})(LoginForm);

export default Login;
