import decode from "jwt-decode";
import { getToken } from "../../services/api/axiosDefaults";

const checkAuth = () => {
  // check token validation, if auth fails return false
  let token = getToken();
  // console.log("token decoded ", decode(token))

  if (!token) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      // clear localstorage
      localStorage.removeItem("he-admin-access");
      localStorage.removeItem("he-admin-details");
      window.location.href = "/";
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

export default checkAuth;
