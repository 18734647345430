import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/accounting/actions";
import { fetchPaymentTerms as fetchPaymentTermsApi } from "../../api/payment-terms";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchPaymentTerms(actions) {
  const {query} = actions
	try {
		const response = yield call(fetchPaymentTermsApi, query);
		const resp = response && response?.data?.results;
		yield put(Creators.fetchPaymentTermsSuccess(resp));
	} catch (error) {
		rollbar.error(`Fetch payment terms ${error}`)
		OpenNotificationWithIcon(
			"error",
			"Get Payment Terms",
			"Failed to fetch payment terms!"
		);
		yield put(Creators.fetchPaymentTermsFailure());
	}
}

export function* watchFetchPaymentTerms() {
	yield takeLatest(Types.FETCH_PAYMENT_TERMS, fetchPaymentTerms);
}
