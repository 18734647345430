import React from "react";
import PageHeader from "../../../reusable-components/Header";
import { Layout } from "antd";

const { Content } = Layout;

class LayoutPage extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Layout>
          <PageHeader />
          <Content style={{ width: "100%", padding: "100px" }}>{children}</Content>
        </Layout>
      </>
    );
  }
}

export default LayoutPage;
