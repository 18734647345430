import * as yup from "yup";

export const emailNotLongEnough = "Email must be atleast 3 characters";
export const passwordNotLongEnough = "Password must be atleast 8 characters";
export const invalidEmail = "Email must be a valid email";
export const fieldRequired = "Field is required";
export const positiveNumber = "Positive number is required";

// setup reusable yup fxns...
export const passwordValidation = yup
	.string()
	.max(255)
	.required("Password is required");
// 9S8/~J:xBa$M
export const passwordExtraValidation = yup
	.string()
	.matches(
		// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,20}$/,
		/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{4,}$/,
		{
			message:
				"Password should contain atleast one number, one lowercase, one uppercase, and one special character",
			excludeEmptyString: true,
		}
	)
	.min(8, passwordNotLongEnough)
	.max(16, "Password should have a maximum of 16 characters")
	.required("Password is required");

// setup reusable yup fxns...
export const registerPasswordValidation = yup
	.string()
	.min(8, passwordNotLongEnough)
	.max(255)
	.required("Password is required")
	.nullable();

export const validationSchemaLoginForm = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	password: passwordValidation,
});

export const validationSchemaForgotForm = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
});

export const validationSchemaResetForm = yup.object().shape({
	password: passwordExtraValidation,
	confirmPassword: yup
		.string()
		.required("Confirm Password is required")
		.oneOf([yup.ref("password"), null], "Passwords do not match"),
});
