import React from "react";
import { Form, Input } from "antd";
import { getMapValue } from "../../util/helpers/reusable-functions";

const FormItem = Form.Item;

export const InputField = ({
	label,
	field,
	form: { touched, errors },
	...props
}) => {
	let errorMsg = errors[field.name];

	const { formerrors } = props;
	const errorsFound =
		touched &&
		formerrors &&
		formerrors.hasOwnProperty(field.name) &&
		getMapValue(formerrors, field.name).map((err) => {
			return err;
		});

	if (errorsFound) {
		errorMsg = errorsFound;
	}

	return (
		<FormItem
			label={label}
			validateStatus={errorMsg ? "error" : ""}
			help={errorMsg}
			className="custom-label"
		>
			<Input
				{...field}
				{...props}
				type={props.type ? props.type : "text"}
				onChange={(e) => {
					field.onChange(e);
					props.onChange && props.onChange(e);
				}}
				onBlur={(e) => props.onBlur && props.onBlur(e)}
				style={{ ...(props.styles || props.style) }}
				className="form-input"
			/>
		</FormItem>
	);
};
