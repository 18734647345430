import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchPromotions: ["query"],
    fetchPromotionsSuccess: ["promotions", "totalCount"],
    fetchPromotionsFailure: ["error"],

    fetchPromotion: ["id"],
    fetchPromotionSuccess: ["responseData"],
    fetchPromotionFailure: ["error"],

    createPromotion: ["payload"],
    createPromotionSuccess: ["responseData"],
    createPromotionFailure: ["error"],

    updatePromotion: ["id", "payload"],
    updatePromotionSuccess: ["responseData"],
    updatePromotionFailure: ["error"],

    deletePromotion: ["id"],
    deletePromotionSuccess: ["id"],
    deletePromotionFailure: ["error"],

    resetPromotions: null,
  },
  {}
);
