import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/trainings/actions";
import {
  fetchTrainings as fetchTrainingsApi,
  fetchTrainingById as fetchTrainingByIdApi,
  updateTraining as updateTrainingApi,
  syncTrainings as syncTrainingsApi,
  trackTrainingSync as trackTrainingSyncApi
} from "../../api/trainings";

import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";
import { message } from "antd";

export function* fetchTrainings(actions) {
  const { query } = actions
	try {
		const response = yield call(fetchTrainingsApi, query);
		yield put(Creators.fetchTrainingsSuccess(response?.data));
	} catch (e) {
		rollbar.error(`Api call fetch trainings error ${e}`)
		const message =
			(e && e?.response?.data && e.response?.data?.message) ||
			"Cannot Fetch User Groups";
		OpenNotificationWithIcon("error", "Fetching User Groups Failed!", message);
		yield put(Creators.fetchTrainingsFailure(message));
	}
}

export function* fetchTrainingById(actions) {
  const { id } = actions
	try {
		const response = yield call(fetchTrainingByIdApi, id);
		const resp = response && response.data;

		yield put(Creators.fetchTrainingByIdSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call fetch user group products error ${error}`)
    message.error("Failed to fetch Trainings")
		yield put(Creators.fetchTrainingByIdFailure());
	}
}

export function* updateTraining(actions) {
  const { id, data } = actions
	try {
		const response = yield call(updateTrainingApi, id, data);
		const resp = response && response.data;

		yield put(Creators.updateTrainingSuccess(resp));

    const responseMsg = resp?.success || resp?.message
    OpenNotificationWithIcon(
      "success",
      "Update Training",
      responseMsg
    )
	} catch (error) {
		rollbar.error(`Api call fetch user group products error ${error}`)
		let errorsFound = error?.response?.data?.message || "Failed to update training!";
		OpenNotificationWithIcon(
			"error",
			"Update Training",
			errorsFound
		);
		yield put(Creators.updateTrainingFailure(errorsFound));
	}
}

export function* syncTrainings(action) {
  const {query} = action
  try {
    const response = yield call(syncTrainingsApi, query)
    const responseData = response?.data
    console.log('sync training response data', responseData)
    const data = {
      message: responseData?.message,
      task_id: responseData?.task_id
    }
    yield put(Creators.syncTrainingsSuccess(data))
  } catch(e){
    yield put(Creators.syncTrainingsFailure("Failed to sync trainings"))
  }
}

export function* getLastTrainingsSync(action) {
  try {
    const response = yield call(syncTrainingsApi, {last_run: true})
    console.log('last sync response', response?.data)
    yield put(Creators.getLastTrainingsSyncSuccess(response?.data))
  } catch(e){
    yield put(Creators.getLastTrainingsSyncFailure("Failed to get last training sync"))
  }
}

export function* trackTrainingsSync(actions) {
	try {
		const { taskId, query } = actions
    rollbar.info(`Track user sync: ${taskId}`)
		const response = yield call(trackTrainingSyncApi, taskId)
    const responseData = response?.data

    const data = {
      status: responseData?.state,
      complete: responseData?.complete,
      success: responseData?.success,
      pending: responseData?.progress?.pending,
      current: responseData?.progress?.current,
      total: responseData?.progress?.total,
      percent: responseData?.progress?.percent,
      description: responseData?.progress?.description,
    }
		yield put(Creators.trackTrainingsSyncSuccess(data))
    if(data?.complete) {
      OpenNotificationWithIcon('success', 'Training Sync Successful')
      yield put(Creators.fetchTrainings(query))
    }
		//yield put(PaginationCreators.reset())
		//yield put(Creators.fetchUsers({page: 1, limit: 50, country: query.country, district: query.district}))
	} catch (error) {
		rollbar.error(`Api call refresh user error ${error}`)
		//OpenNotificationWithIcon("error", "Refresh Trainings", "Failed to refresh trainings!");
		yield put(Creators.trackTrainingsSyncFailure());
	}
}

export function* watchFetchTrainings() {
	yield takeLatest(Types.FETCH_TRAININGS, fetchTrainings)
}

export function* watchFetchTrainingById() {
	yield takeLatest(Types.FETCH_TRAINING_BY_ID, fetchTrainingById)
}

export function* watchUpdateTraining() {
	yield takeLeading(Types.UPDATE_TRAINING, updateTraining)
}

export function* watchSyncTrainings() {
  yield takeLeading(Types.SYNC_TRAININGS, syncTrainings)
}

export function* watchGetLastTrainingsSync() {
  yield takeLatest(Types.GET_LAST_TRAININGS_SYNC, getLastTrainingsSync)
}

export function* watchtrackTrainingsSync() {
  yield takeLatest(Types.TRACK_TRAININGS_SYNC, trackTrainingsSync)
}