import { apiRequest } from "../apiRequest";
import { PAYMENTS_ENDPOINT } from "../urls";
import { serializeQueryParamsOnNull } from "../../../util/helpers/commonHelper"

export const fetchPayments = (query) => {
	let search_term = query?.search_term;
	let start_date = query?.start_date;
	let end_date = query?.end_date;
	let status = query?.status;
	let page = query?.page;
  let provider = query?.provider
	let limit = 20;

  const queryStr = serializeQueryParamsOnNull({
    search: search_term,
    created_at__lte: end_date,
    created_at__gte: start_date,
    status,
    provider,
    page,
    limit
  })

  return apiRequest(
		"GET",
		`${PAYMENTS_ENDPOINT}/overview${queryStr}`
	);
};

export const fetchPaymentDetails = (id) => {
	return apiRequest("GET", `${PAYMENTS_ENDPOINT}/${id}/`);
};