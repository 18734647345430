import { apiRequest, apiCustomised } from "../apiRequest";
import {
	CREATE_STAFF_ENDPOINT,
	STAFF_ENDPOINT,
	SET_STAFF_PASSWORD_VALID_LINK_ENDPOINT,
	SET_STAFF_PASSWORD,
} from "../urls";

export const getStaff = () => {
	return apiRequest("GET", `${STAFF_ENDPOINT}?limit=100`);
};

export const deleteStaff = (id) => {
	return apiRequest("DELETE", `${STAFF_ENDPOINT}/${id}`);
};

export const addStaff = (data) => {
	return apiRequest("POST", CREATE_STAFF_ENDPOINT, data);
};

export const editStaff = (id, data) => {
	return apiRequest("PATCH", `${STAFF_ENDPOINT}/${id}/update`, data);
};

export const validateSetPasswordLink = (uuid, activationToken) => {
	return apiRequest(
		"GET",
		`${SET_STAFF_PASSWORD_VALID_LINK_ENDPOINT}${uuid}/${activationToken}`
	);
};

export const setPassword = (token, password, email) => {
	return apiCustomised("PATCH", SET_STAFF_PASSWORD, { password }, token, email);
};
