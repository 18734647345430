import apiInstance, {
	axiosInstance,
	axiosInstanceOthers,
	axiosInstanceCustomised,
} from "./axiosDefaults";

export const apiRequest = (method, url, data = {}) => {
	const response = axiosInstance()({
		method,
		url,
		data,
		withCredentials: false,
	});
	return response;
};

export const apiBlobRequest = (method, url, data = {}) => {
	return axiosInstance()({
		method,
		url,
		data,
		withCredentials: false,
		responseType: 'blob'
	});
};

export const apiRequestOthers = (
	method,
	url,
	data = {},
	headerType,
	responseType
) => {
	const response = axiosInstanceOthers(
		headerType,
		responseType
	)({
		method,
		url,
		data,
		withCredentials: false,
	});
	return response;
};

export const api = (method, url, data = {}) => {
	const response = apiInstance({
		method,
		url,
		data,
	});

	return response;
};

export const apiCustomised = (method, url, data = {}, token, email) => {
	const response = axiosInstanceCustomised(
		token,
		email
	)({
		method,
		url,
		data,
	});

	return response;
};
