import { apiRequest } from "../apiRequest";
import { ORDERS_ENDPOINT } from "../urls";

export const fetchOrders = (query) => {
	let search_term = query && query.search_term;
	let from_date = query && query.from_date;
	let end_date = query && query.end_date;
	let status = query && query.status;
	let page = query && query.page;
	let limit = 20;

	return apiRequest(
		"GET",
		`${ORDERS_ENDPOINT}/?
		${search_term ? `&search=${search_term}` : ""}
		${from_date ? `&date_order__gte=${from_date}` : ""}
		${end_date ? `&date_order__lte=${end_date}` : ""}
		${status ? `&x_stored_order_status=${status}` : ""}
		${page ? `&page=${page}` : ""}
		${limit ? `&limit=${limit}` : ""}`
	);
};

export const fetchOrderDetails = (id) => {
	return apiRequest("GET", `${ORDERS_ENDPOINT}/${id}/`);
};
