import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_TRAININGS
  FETCH_TRAININGS_SUCCESS
  FETCH_TRAININGS_FAILURE

  FETCH_TRAINING_BY_ID
  FETCH_TRAINING_BY_ID_SUCCESS
  FETCH_TRAINING_BY_ID_FAILURE

  UPDATE_TRAINING
  UPDATE_TRAINING_SUCCESS
  UPDATE_TRAINING_FAILURE

  UPDATE_FREE_PRODUCTS
  UPDATE_FREE_PRODUCTS_SUCCESS
  UPDATE_FREE_PRODUCTS_FAILURE

  SYNC_TRAININGS
  SYNC_TRAININGS_SUCCESS
  SYNC_TRAININGS_FAILURE

  TRACK_TRAININGS_SYNC
  TRACK_TRAININGS_SYNC_SUCCESS
  TRACK_TRAININGS_SYNC_FAILURE

  GET_LAST_TRAININGS_SYNC
  GET_LAST_TRAININGS_SYNC_SUCCESS
  GET_LAST_TRAININGS_SYNC_FAILURE


  ADD_FREE_PRODUCT
  REMOVE_FREE_PRODUCT
  EDIT_FREE_PRODUCT

  RESET_TRAINING
`,
  {}
);
