import { createTypes } from "reduxsauce";

export default createTypes(
	`
  FETCH_PAYMENT_METHODS
  FETCH_PAYMENT_METHODS_SUCCESS
  FETCH_PAYMENT_METHODS_FAILURE
`,
	{}
);
