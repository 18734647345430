import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    loginRequest: ["email", "password"],
    loginSuccess: ["responseData"],
    loginFailure: ["error"],

    signUpRequest: ["payload"],
    signUpSuccess: ["responseData"],
    signUpFailure: ["error"],

    logout: null,

    forgotPassword: ["email"],
    forgotPasswordSuccess: ["responseData"],
    forgotPasswordFailure: ["error"],

    resetPassword: ["token", "password"],
    resetPasswordSuccess: ["responseData"],
    resetPasswordFailure: ["error"],

    changePasswordRequest: ["id", "payload"],
    changePasswordSuccess: ["responseData"],
    changePasswordFailure: ["error"],

    getUserDetails: null,
    getUserDetailsSuccess: ["responseData"],
    getUserDetailsFailure: ["error"],

    saveDeviceToken: ["registration_id"],
    saveDeviceTokenSuccess: ["responseData"],
    saveDeviceTokenFailure: ["error"],

    reset: null,
  },
  {}
);
