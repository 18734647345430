import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/auth/actions";
import {
	login as loginApi,
	signUp as signUpApi,
	resetPassword as resetPasswordApi,
	forgotPassword as forgotPasswordApi,
	changePassword as changePasswordApi,
	getUserDetails as getUserDetailsApi,
  saveDeviceToken as saveDeviceTokenApi
} from "../../api/auth";
import { storeLocalState, clearLocalState } from "../../app-storage";
import { history } from "../../../util/helpers/browserHistory";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* login(actions) {
	try {
		const { email, password } = actions;

		const response = yield call(loginApi, { email, password });
		const resp = response.data;

		storeLocalState("he-admin-access", resp);
		// storeLocalState("he-admin-access", resp && resp.access_token);
		// storeLocalState("he-admin-refresh", resp && resp.refresh_token);

		yield put(Creators.loginSuccess(resp));
		yield put(Creators.getUserDetails());

		history.push("/app/orders");
	} catch (e) {
		rollbar.error(`Login Error ${e}`)
		const message =
			(e && e.response.data && e.response.data.error) || "Invalid Credentials";
		yield put(Creators.loginFailure(message));
	}
}

export function* signUp(actions) {
	try {
		const { payload } = actions;
		const response = yield call(signUpApi, payload);
		const resp = response && response.data;

		storeLocalState("he-admin-access", resp);

		yield put(Creators.signUpSuccess(resp));
	} catch (e) {
		rollbar.error(`Sign up error ${e}`)
		const message = "Invalid Credentials";
		OpenNotificationWithIcon("error", "Sign Up failed", message);
		yield put(Creators.signUpFailure(message));
	}
}

export function* forgotPassword(actions) {
	try {
		const { email } = actions;
		const payload = { email };
		const response = yield call(forgotPasswordApi, payload);
		const resp = response && response.data;
		yield put(Creators.forgotPasswordSuccess(resp));
	} catch (e) {
		rollbar.error(`post forgot password error ${e}`)
		const message =
			(e && e.response.data && e.response.data.error) || "Invalid email address";
		yield put(Creators.forgotPasswordFailure(message));
	}
}

export function* resetPassword(actions) {
	try {
		const { token, password } = actions;
		const payload = { password };
		yield call(resetPasswordApi, token, payload);
		const message =
			"An email has been sent to your inbox. Kindly click on the link in it to complete your password change.";
		yield put(Creators.resetPasswordSuccess(message));
	} catch (e) {
		rollbar.error(`Api call reset password error ${e}`)
		let message =
			(e && e.response.data && e.response.data.error) || "Failed to reset password.";
		//TODO backend should send correct error message
		if(message === 'Invalid token for password update!') {
			message = 'Link has previously been used'
		}
		OpenNotificationWithIcon("error", "Password Reset!", message);
		yield put(Creators.resetPasswordFailure(message));
	}
}

export function* changePassword(actions) {
	try {
		const { id, payload } = actions;
		yield call(changePasswordApi, id, payload);

		const message = "Password has been changed successfully.";
		yield put(Creators.changePasswordSuccess(message));
	} catch (error) {
		rollbar.error(`Api call change password error ${error}`)
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"Password Change!",
			errorsFound && errorsFound.message
		);
		yield put(Creators.changePasswordFailure(errorsFound && errorsFound.message));
	}
}

export function* getUserDetails(actions) {
	try {
		const { id } = actions;
		const response = yield call(getUserDetailsApi, id);
		const resp = response && response.data;
		storeLocalState("he-admin-details", resp);

		yield put(Creators.getUserDetailsSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call get user details error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getUserDetailsFailure(errorsFound));
	}
}

export function* saveDeviceToken(actions) {
	try {
		const { registration_id } = actions;
		const response = yield call(saveDeviceTokenApi, { registration_id });
		yield put(Creators.saveDeviceTokenSuccess(response));
		localStorage.setItem("token_saved", true)
	} catch (error) {
		rollbar.error(`Save Device Token Error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.saveDeviceTokenFailure(errorsFound));
	}
}

export function* logout() {
	// clear localStorage
	clearLocalState("he-admin-access");
	// clearLocalState("he-admin-refresh");
	clearLocalState("he-admin-details");

	yield put(Creators.logout());

	// redirect to landing page
	window.location.href = "/";
}

export function* watchLogin() {
	yield takeLeading(Types.LOGIN_REQUEST, login);
}

export function* watchSignUp() {
	yield takeLeading(Types.SIGN_UP_REQUEST, signUp);
}

export function* watchForgotPassword() {
	yield takeLeading(Types.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
	yield takeLeading(Types.RESET_PASSWORD, resetPassword);
}

export function* watchChangePassword() {
	yield takeLeading(Types.CHANGE_PASSWORD_REQUEST, changePassword);
}

export function* watchGetUserDetails() {
	yield takeLeading(Types.GET_USER_DETAILS, getUserDetails);
}

export function* watchLogout() {
	yield takeLeading(Types.LOGOUT, logout);
}

export function* watchSaveDeviceToken() {
	yield takeLeading(Types.SAVE_DEVICE_TOKEN, saveDeviceToken);
}
