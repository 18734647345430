import { connect } from "react-redux";
import { Creators } from "../../services/redux/auth/actions";
import Login from "./components";

const mapStateToProps = (state) => {
  const { isloading, linkSent, isRecovered, errors } = state.auth;
  return {
    isloading,
    formerrors: errors,
    linkSent,
    isRecovered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password) => {
      dispatch(Creators.loginRequest(email, password));
    },
    signUpUser: (data) => {
      dispatch(Creators.signUpRequest(data));
    },
    forgotPassword: (email) => {
      dispatch(Creators.forgotPassword(email));
    },
    resetPassword: (token, password) => {
      dispatch(Creators.resetPassword(token, password));
    },
    reset: () => {
      dispatch(Creators.reset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
