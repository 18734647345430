import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  fetching: false,
  createSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  creating: false,
  updating: false,
  deleting: false,
  promotions: [],
  promotion: null,
  totalCount: 0,
  fetchingPrmotionDetails: false
};

export const fetchPromotions = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, fetching: true };
};

export const fetchPromotionsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    promotions: action.promotions,
    totalCount: action.totalCount,
    fetching: false,
  };
};

export const fetchPromotionsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetching: false, errors: action.error };
};

export const fetchPromotionDetails = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, fetchingPrmotionDetails: true };
};

export const fetchPromotionDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    detail: action.responseData,
    fetchingPrmotionDetails: false,
  };
};

export const fetchPromotionDetailsFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    fetchingPrmotionDetails: false, 
    errors: action.error, 
    detail: null
  };
};

export const createPromotion = (state = INITIAL_STATE, action) => {
  return { ...state, creating: true, error: "", createSuccess: false }
}

export const createPromotionSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, creating: false, error: "", createSuccess: true }
}

export const createPromotionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, creating: false, error: error, createSuccess: false }
}

export const updatePromotion = (state = INITIAL_STATE, action) => {
  return { ...state, updating: true, error: "", updateSuccess: false }
}

export const updatePromotionSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updating: false, error: "", updateSuccess: true }
}

export const updatePromotionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, updating: false, error: error, updateSuccess: false }
}

export const deletePromotion = (state = INITIAL_STATE, action) => {
  return { ...state, deleting: true, error: "", deleteSuccess: false }
}

export const deletePromotionSuccess = (state = INITIAL_STATE, action) => {
  console.log('in delete reducer', action)
  const { id } = action

  return { 
    ...state, 
    deleting: false, 
    error: "",
    deleteSuccess: true,
    promotions: state?.promotions?.filter(item=> item.id !== id)
  }
}

export const deletePromotionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, deleting: false, error, deleteSuccess: false }
}

export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    fetching: false,
    errors: null,
    promotions: [],
    totalCount: null,
  };
};

export const HANDLERS = {
  [Types.FETCH_PROMOTIONS]: fetchPromotions,
  [Types.FETCH_PROMOTIONS_SUCCESS]: fetchPromotionsSuccess,
  [Types.FETCH_PROMOTIONS_FAILURE]: fetchPromotionsFailure,

  [Types.FETCH_PROMOTION]: fetchPromotionDetails,
  [Types.FETCH_PROMOTION_SUCCESS]: fetchPromotionDetailsSuccess,
  [Types.FETCH_PROMOTION_FAILURE]: fetchPromotionDetailsFailure,

  [Types.CREATE_PROMOTION]: createPromotion,
  [Types.CREATE_PROMOTION_SUCCESS]: createPromotionSuccess,
  [Types.CREATE_PROMOTION_FAILURE]: createPromotionFailure,

  [Types.UPDATE_PROMOTION]: updatePromotion,
  [Types.UPDATE_PROMOTION_SUCCESS]: updatePromotionSuccess,
  [Types.UPDATE_PROMOTION_FAILURE]: updatePromotionFailure,

  [Types.DELETE_PROMOTION]: deletePromotion,
  [Types.DELETE_PROMOTION_SUCCESS]: deletePromotionSuccess,
  [Types.DELETE_PROMOTION_FAILURE]: deletePromotionFailure,

  [Types.RESET_PROMOTIONS]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
