import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchPaymentTerms: ["query"],
    fetchPaymentTermsSuccess: ["responseData"],
    fetchPaymentTermsFailure: ["error"],

    resetPaymentTerms: []
  },
  {}
);
