import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/payment_methods/actions";
import { fetchPaymentMethods as fetchPaymentMethodsApi } from "../../api/payment_methods";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchPaymentMethods(actions) {
	try {
		const response = yield call(fetchPaymentMethodsApi);
		const resp = response && response.data;
		yield put(Creators.fetchPaymentMethodsSuccess(resp));
	} catch (error) {
		rollbar.error(`Api call fetch payment terms error ${error}`)
		OpenNotificationWithIcon(
			"error",
			"Get Payment Methods",
			"Failed to fetch payment methods!"
		);
		yield put(Creators.fetchPaymentMethodsFailure());
	}
}

export function* watchFetchPaymentMethods() {
	yield takeLatest(Types.FETCH_PAYMENT_METHODS, fetchPaymentMethods);
}
