import React from "react";
import { Col, Row, Image, Dropdown } from "antd";
import { Menu } from "antd";
import { history } from "../../../util/helpers/browserHistory";
import Logo from "../../../assets/logo.png";
import { DownOutlined } from "@ant-design/icons";
import { getLocalState } from "../../../services/app-storage";

import "../../../styles/header.less";

const getUserName = () => {
	let user = getLocalState("he-admin-details");
	return (user && user.username) || "";
};

class WebHeader extends React.Component {
	state = {
		current: "orders",
	};

	handleClick = (e) => {
		this.setState({ current: e.key });
	};

	componentDidMount() {
		const { location } = this.props;
		let key = null;
		switch (location.pathname) {
			case "/app/orders":
				key = "orders";
				break;
      case "/app/payments":
        key = "payments";
        break;
			case "/app/companies":
				key = "companies";
				break;
			case "/app/ches":
			case "/app/view/che":
				key = "users";
				break;
			case "/app/staff":
				key = "staff";
				break;
			case "/app/user-groups":
				key = "user-groups";
				break;
      case "/app/trainings":
        key = "trainings";
        break;
			case "/app/promotions":
				key = "promotions"
				break;
			default:
				key = "orders";
		}
		this.setState({ current: key });
	}

	render() {
		const { logout } = this.props;
		const { current } = this.state;

		const menu = (
			<Menu>
				<Menu.Item key="logout-sub-menu" onClick={() => logout()}>
					Logout
				</Menu.Item>
			</Menu>
		);

		return (
			<>
				<Row>
					<Col md={4} sm={24} xs={24}>
						<Image
              height={40}
							preview={false}
							src={Logo}
							className="logo"
							onClick={() => (window.location.href = "/app/orders")}
						/>
					</Col>
					<Col md={20} sm={24} xs={24} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
						<Menu
							onClick={this.handleClick}
							selectedKeys={[current]}
							mode="horizontal"
						>
							<Menu.Item key="orders" onClick={() => history.push("/app/orders")}>
								Orders
							</Menu.Item>
							<Menu.Item
								key="companies"
								onClick={() => history.push("/app/companies")}
							>
								Companies
							</Menu.Item>
							<Menu.Item key="users" onClick={() => history.push("/app/ches")}>
								Users
							</Menu.Item>
							<Menu.Item key="staff" onClick={() => history.push("/app/staff")}>
								Staff
							</Menu.Item>
              <Menu.Item key="payments" onClick={() => history.push("/app/payments")}>
								Payments
							</Menu.Item>
							{/* <Menu.Item
								key="user-groups"
								onClick={() => history.push("/app/user-groups")}
							>
								User Groups
							</Menu.Item> */}
              <Menu.Item
								key="trainings"
								onClick={() => history.push("/app/trainings")}
							>
								Trainings
							</Menu.Item>
							<Menu.Item
								key="promotions"
								onClick={() => history.push("/app/promotions")}
							>
								Promotions
							</Menu.Item>
							<Menu.Item key="logout">
								<Dropdown overlay={menu}>
									<a
										href="/#"
										className="ant-dropdown-link"
										onClick={(e) => e.preventDefault()}
									>
										{getUserName()} <DownOutlined />
									</a>
								</Dropdown>
							</Menu.Item>
						</Menu>
					</Col>
				</Row>
			</>
		);
	}
}

export default WebHeader;
