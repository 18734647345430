import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
	{
		fetchCountries: ["query"],
		fetchCountriesSuccess: ["responseData"],
		fetchCountriesFailure: ["error"],

		updateCountry: ["id", "data"],
		updateCountrySuccess: ["responseData"],
		updateCountryFailure: ["error"],

		chooseCompany: ["data"],

		toggleSync: null,
	},
	{}
);
