import { createReducer } from "reduxsauce";
import Types from './actionTypes'

export const INITIAL_STATE = {
  errors: null,
  isLoading: false,
  clusters: [],
  totalCount: 0,
}

export const fetchClusters = (state=INITIAL_STATE, action) => {
  return {...state, errors: null, isLoading: true}
}

export const fetchClustersSuccess = (state=INITIAL_STATE, action) => {
  return {...state, clusters: action.responseData, totalCount: action.totalCount, isLoading: false}
}

export const fetchClustersFailure = (state=INITIAL_STATE, action) => {
  return {...state, errors: action.error, isLoading: false}
}

export const resetClusters = (state=INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    error: null,
    totalCount: 0,
    clusters: []
  }
}

export const HANDLERS = {
  [Types.FETCH_CLUSTERS]: fetchClusters,
  [Types.FETCH_CLUSTERS_SUCCESS]: fetchClustersSuccess,
  [Types.FETCH_CLUSTERS_FAILURE]: fetchClustersFailure,
  [Types.RESET_CLUSTERS]: resetClusters
}

export default createReducer(INITIAL_STATE, HANDLERS)