import { createTypes } from "reduxsauce";

export default createTypes(
	`
  FETCH_COUNTRIES
  FETCH_COUNTRIES_SUCCESS
  FETCH_COUNTRIES_FAILURE

  UPDATE_COUNTRY
  UPDATE_COUNTRY_SUCCESS
  UPDATE_COUNTRY_FAILURE

  CHOOSE_COMPANY

  TOGGLE_SYNC
`,
	{}
);
