import { createReducer } from "reduxsauce";
import Types from "./actionTypes";
import get from "lodash/get";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  isFetching: false,
  user: {},
  users: [],
  totalCount: 0,
  generated_password: null,
  reset_pwd_errors: null,
  isPasswordReset: false,
  isDeactivated: false,
  generatedChesPasswords: null,
  generatingChesPasswords: false,
  refreshing: false,
  filter: {search_term: undefined, company_id: undefined, district_id: undefined, status: undefined, cluster_id: undefined},
  generatedChesActivationQrcode: null,
  generatingQrcode: false,
  generateQrcodeSuccess: false,

  syncingUsers: false,
  syncUsersMessage: 'Syncing Users',
  syncTaskId: null,
  syncUserStatus: 'init',
  syncTotal: 0,
  syncPending: false,
  syncPercent: 0,
  syncDescription: null,
  syncUserCurrent: null,
  trackinUserSync: false,

  loadingLastSync: false,
  lastSyncStatus: true,
  lastSyncTime: null,
  lastSyncData: null
};

export const fetchUsers = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true, generatedChesActivationQrcode: null, generatingChesPasswords: false };
};

export const fetchUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    users: action.responseData,
    totalCount: action.totalCount,
    isloading: false,
  };
};

export const fetchUsersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const refreshUsers = (state = INITIAL_STATE, action) => {
  return { ...state, refreshing: true, errors: null }
}

export const refreshUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    refreshing: false,
  };
};

export const refreshUsersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, refreshing: false, errors: action.error };
};

export const getUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetching: true };
};

export const getUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isFetching: false };
};

export const getUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, errors: action.error };
};

export const createUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const createUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false };
};

export const createUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const updateUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const updateUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false };
};

export const updateUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const resetChePassword = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reset_pwd_errors: null,
    isloading: true,
    isPasswordReset: false,
  };
};

export const resetChePasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    generated_password: get(action.responseData, "password"),
    isloading: false,
    isPasswordReset: true,
  };
};

export const resetChePasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, reset_pwd_errors: action.error };
};

export const deactivateUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isDeactivated: false };
};

export const deactivateUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isDeactivated: true };
};

export const deactivateUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDeactivated: false, errors: action.error };
};

export const resetPasswordGeneration = (state = INITIAL_STATE, action) => {
  return { ...state, isPasswordReset: false, generated_password: null };
};

export const generateChesPasswords = (state = INITIAL_STATE, action) => {
  return { ...state, generatingChesPasswords: true, generatedChesPasswords: null}
}

export const generateChesPasswordsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, generatingChesPasswords: false}
}

export const generateChesPasswordsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, generatingChesPasswords: false, generatedChesPasswords: null }
}

export const generateChesActivationQrcode = (state = INITIAL_STATE, action) => {
  return { ...state, generatingQrcode: true, generatedChesActivationQrcode: null, generateQrcodeSuccess: false}
}

export const generateChesActivationQrcodeSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, generatingQrcode: false, generatedChesActivationQrcode: action.responseData, generateQrcodeSuccess: true}
}

export const generateChesActivationQrcodeFailure = (state = INITIAL_STATE, action) => {
  return { ...state, generatingQrcode: false, generatedChesActivationQrcode: null, generateQrcodeSuccess: false, error: action.error}
}

export const setFilter = (state = INITIAL_STATE, action) => {
  const filter = action.filter
  const filterObj = {
    search_term: filter?.search_term || null,
    company_id: filter?.company_id || null,
    status: filter?.status || null,
    district_id: filter?.district_id || null,
    cluster_id: filter?.cluster_id || null
  }
  return {
    ...state, 
    filter: filterObj
  }
}

export const resetFilter = (state=INITIAL_STATE, action) => {
  return {
    ...state,
    filter: {search_term: undefined, company_id: undefined, district_id: undefined, status: undefined, cluster_id: undefined}
  }
}

export const syncUsers = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    syncingUsers: true,
  }
}

export const syncUsersSuccess = (state = INITIAL_STATE, action) => {
  const {  
    status,
    task_id,
    total,
    pending,
    percent,
    message
  } = action?.responseData
  return {
    ...state,
    syncingUsers: true,
    syncUsersMessage: message,
    syncUserStatus: status,
    syncTotal: total,
    syncPending: pending,
    syncPercent: percent ? percent : 0,
    syncTaskId: task_id
  };
};

export const syncUsersFailure = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    syncingUsers: false
  };
};

export const trackUserSync = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    trackinUserSync: true,
  }
}

export const trackUserSyncSuccess = (state = INITIAL_STATE, action) => {
  const {  
    status,
    complete,
    pending,
    current,
    total,
    percent,
  } = action?.responseData
  return {
    ...state,
    syncingUsers: !complete,
    syncUserStatus: status,
    syncTotal: total,
    syncPending: pending,
    syncPercent: percent ? percent : 0,
    trackinUserSync: false,
    syncUserCurrent: current,
  };
};

export const trackUserSyncFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    trackUserSync: false
  };
};

export const getLastSync = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    loadingLastSync: true,
  }
}

export const getLastSyncSuccess = (state = INITIAL_STATE, actions) => {
  const { responseData } = actions
  return {
    ...state,
    loadingLastSync: false,
    lastSyncStatus: responseData?.data?.success,
    lastSyncTime: responseData?.data?.last_sync_time || null,
    lastSyncData: responseData?.data
  }
}

export const getLastSyncFailure = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    loadingLastSync: false,
  }
}



export const reset = (state = INITIAL_STATE, action) => {
  const userObj = {
    name: undefined,
    email: undefined,
    phone_number: undefined,
    role: undefined,
    filter: {search_term: undefined, company_id: undefined, district_id: undefined, status: undefined, cluster_id: undefined}
  };
  return {
    ...state,
    isloading: false,
    errors: null,
    user: userObj,
    users: [],
    totalCount: 0,
  };
};

export const HANDLERS = {
  [Types.FETCH_USERS]: fetchUsers,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.REFRESH_USERS]: refreshUsers,
  [Types.REFRESH_USERS_SUCCESS]: refreshUsersSuccess,
  [Types.REFRESH_USERS_FAILURE]: refreshUsersFailure,
  [Types.SYNC_USERS]: syncUsers,
  [Types.SYNC_USERS_SUCCESS]: syncUsersSuccess,
  [Types.SYNC_USERS_FAILURE]: syncUsersFailure,
  [Types.TRACK_USER_SYNC]: trackUserSync,
  [Types.TRACK_USER_SYNC_SUCCESS]: trackUserSyncSuccess,
  [Types.TRACK_USER_SYNC_FAILURE]: trackUserSyncFailure,
  [Types.CREATE_USER]: createUser,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.GET_USER]: getUser,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
  [Types.UPDATE_USER]: updateUser,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.RESET_CHE_PASSWORD]: resetChePassword,
  [Types.RESET_CHE_PASSWORD_SUCCESS]: resetChePasswordSuccess,
  [Types.RESET_CHE_PASSWORD_FAILURE]: resetChePasswordFailure,
  [Types.DEACTIVATE_USER]: deactivateUser,
  [Types.DEACTIVATE_USER_SUCCESS]: deactivateUserSuccess,
  [Types.DEACTIVATE_USER_FAILURE]: deactivateUserFailure,
  [Types.RESET_PASSWORD_GENERATION]: resetPasswordGeneration,
  [Types.GENERATE_CHES_PASSWORDS]: generateChesPasswords,
  [Types.GENERATE_CHES_PASSWORDS_SUCCESS]: generateChesPasswordsSuccess,
  [Types.GENERATE_CHES_PASSWORDS_FAILURE]: generateChesPasswordsFailure,
  [Types.RESET]: reset,
  [Types.SET_FILTER]: setFilter,
  [Types.RESET_FILTER]: resetFilter,
  [Types.GENERATE_CHES_ACTIVATION_QRCODE]: generateChesActivationQrcode,
  [Types.GENERATE_CHES_ACTIVATION_QRCODE_SUCCESS]: generateChesActivationQrcodeSuccess,
  [Types.GENERATE_CHES_ACTIVATION_QRCODE_FAILURE]: generateChesActivationQrcodeFailure,
  [Types.GET_LAST_SYNC]: getLastSync,
  [Types.GET_LAST_SYNC_SUCCESS]: getLastSyncSuccess,
  [Types.GET_LAST_SYNC_FAILURE]: getLastSyncFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
