import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/promotions/actions";
import { Creators as PaginationCreators } from "../../redux/pagination/actions"
import * as promotionsApi from "../../api/promotions";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import { rollbar } from "../../../util/configs";

export function* fetchPromotions(actions) {
  const { query } = actions
	try {
		const response = yield call(promotionsApi.fetchPromotions, query);
		yield put(Creators.fetchPromotionsSuccess(response?.data?.results, response?.data?.count));
	} catch (error) {
		rollbar.error(`Api call fetch promotions error ${error}`)
		const message = error?.response?.data?.expiry_date?.error || error?.response?.data?.message || "Cannot fetch promotions"
		OpenNotificationWithIcon("error", "Get Promotions", message);
		yield put(Creators.fetchPromotionsFailure(message));
	}
}

export function* createPromotion(actions) {
	const { payload } = actions
	try {
		const response = yield call(promotionsApi.createPromotion, payload)
		yield put(Creators.createPromotionSuccess(response?.data))
		yield put(PaginationCreators.reset())
		yield put(Creators.fetchPromotions({page: 1}))
		OpenNotificationWithIcon("success", "Create Promotion", response.message || "Success")
	} catch (error) {
		rollbar.error(`Api call create promotion error ${error}`)
		const message = error?.response?.data?.expiry_date?.error || error?.response?.data?.message || "Failed to create promotion"
		OpenNotificationWithIcon("error", "Create Promotion", message)
		yield put(Creators.createPromotionFailure(message))
	}
}

export function* updatePromotion(actions) {
	const { id, payload } = actions
	try {
		const response = yield call(promotionsApi.patchPromotion, id, payload)
		yield put(Creators.updatePromotionSuccess(response?.data))
		yield put(PaginationCreators.reset())
		yield put(Creators.fetchPromotions({page: 1}))
		OpenNotificationWithIcon("success", "Update Promotion", response.message || "Success")
	} catch (error) {
		rollbar.error(`Api call update promotion error ${error}`)
		const message = error?.response?.data?.expiry_date?.error || error?.response?.data?.message || "Failed to update promotion"
		OpenNotificationWithIcon("error", "Update Promotion", message)
		yield put(Creators.updatePromotionFailure(error?.response?.data?.message || "Failed to update promotion"))
	}
}

export function* deletePromotion(actions) {
	const { id } = actions
	try {
		const response = yield call(promotionsApi.deletePromotion, id)
		yield put(Creators.deletePromotionSuccess(id))
		OpenNotificationWithIcon("success", "Delete Promotion", response.message || "Success")
	} catch (error) {
		rollbar.error(`Api call delete promotion error ${error}`)
		const message = error?.response?.data?.expiry_date?.error || error?.response?.data?.message || "Failed to delete promotion"
		OpenNotificationWithIcon("error", "Delete Promotion", message)
		yield put(Creators.deletePromotionFailure(message))
	}
}


export function* watchFetchPromotions() {
	yield takeLeading(Types.FETCH_PROMOTIONS, fetchPromotions);
}

export function* watchCreatePromotion() {
	yield takeLeading(Types.CREATE_PROMOTION, createPromotion);
}

export function* watchDeletePromotion() {
	yield takeLeading(Types.DELETE_PROMOTION, deletePromotion)
}

export function* watchUpdatePromotion() {
	yield takeLeading(Types.UPDATE_PROMOTION, updatePromotion)
}