import { useEffect, useRef } from "react";

export const getMapValue = (obj, key) => {
  if (obj.hasOwnProperty(key)) return obj[key];
};

export const uppercaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function numberWithCommas(str) {
  if (str) {
    var parts = str.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return str;
}

export function addInputCommas(str) {
  if (str) {
    str = replaceCommas(str);
    var parts = str.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return str;
}

export function replaceCommas(comma_seperated_str) {
  if (comma_seperated_str) {
    var str = comma_seperated_str.toString();
    str = str.replace(/,/g, "");
    return str;
  }
  return comma_seperated_str;
}
