import { apiRequest } from "../apiRequest";
import { USER_GROUPS_ENDPOINT } from "../urls";

export const fetchUserGroups = () => {
	return apiRequest("GET", USER_GROUPS_ENDPOINT);
};

export const fetchUserGroupProducts = (id) => {
	return apiRequest(
		"GET",
		`${USER_GROUPS_ENDPOINT}${id}/?salt=${Math.floor(Date.now() / 1000)}`
	);
};

// export const fetchUserGroupProducts = (id) => {
// 	return apiRequest(
// 		"GET",
// 		`${USER_GROUPS_ENDPOINT}${id}`
// 	);
// };

export const blackList = (id, data) => {
	return apiRequest("PUT", `${USER_GROUPS_ENDPOINT}${id}/`, data);
};

export const patchUserGroup = (id, data) => {
	return apiRequest("PUT", `${USER_GROUPS_ENDPOINT}${id}/`, data);
}


export const createUserGroupPromotionMessage = (data) => {
	return apiRequest("POST", `${USER_GROUPS_ENDPOINT}promotional_message`, data);
}

export const updateUserGroupPromotionMessage = (id, data) => {
	return apiRequest("PATCH", `${USER_GROUPS_ENDPOINT}promotional_message/${id}`, data);
}