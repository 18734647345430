import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  user: null,
  isloading: false,
  message: null,
  isRecovered: false,
  linkSent: false,
  isFetching: false,

  isSavingDeviceToken: false,
  deviceTokenError: null,
  lastUpdated: Date.now(),
};

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.user, isloading: true, errors: null };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false };
};

export const loginFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error };
};

export const signUp = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const signUpSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false };
};

export const signUpFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error };
};

export const resetPassword = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
    user: action.user,
    isloading: true,
    isRecovered: false,
  };
};

export const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    user: null,
    isloading: false,
    isRecovered: true,
  };
};

export const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    user: null,
    isloading: false,
    errors: action.error,
    isRecovered: false,
  };
};

export const forgotPassword = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
    user: action.user,
    isloading: true,
    linkSent: false,
  };
};

export const forgotPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    user: null,
    isloading: false,
    linkSent: true,
  };
};

export const forgotPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    user: null,
    isloading: false,
    errors: action.error,
    linkSent: false,
  };
};

export const changePassword = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true, message: null };
};

export const changePasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, message: action.responseData, isloading: false };
};

export const changePasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const getUserDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: true, error: null };
};

export const getUserDetailsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isFetching: false };
};

export const getUserDetailsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const logout = (state = INITIAL_STATE, action) => {
  return { ...state, user: null };
};

export const saveDeviceToken = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: true, deviceTokenError: null };
};

export const saveDeviceTokenSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: false };
};

export const saveDeviceTokenFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: false, deviceTokenError: action.error };
};

export const reset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
    user: null,
    message: null,
    linkSent: false,
    isRecovered: false,
  };
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.SIGN_UP_REQUEST]: signUp,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,

  [Types.FORGOT_PASSWORD]: forgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.CHANGE_PASSWORD_REQUEST]: changePassword,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [Types.GET_USER_DETAILS]: getUserDetails,
  [Types.GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccess,
  [Types.GET_USER_DETAILS_FAILURE]: getUserDetailsFailure,

  [Types.SAVE_DEVICE_TOKEN]: saveDeviceToken,
  [Types.SAVE_DEVICE_TOKEN_SUCCESS]: saveDeviceTokenSuccess,
  [Types.SAVE_DEVICE_TOKEN_FAILURE]: saveDeviceTokenFailure,

  [Types.LOGOUT]: logout,

  [Types.RESET]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
